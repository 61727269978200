import React, { Component } from 'react'
import moment from 'moment'

import { dateFriendly } from './utils/dates'
import Select from './Select'

export default class PickerModal extends Component {
  constructor(props) {
    super(props)

    let { date } = props
    this.state = { date }
  }

  handleSelectChange = date => {
    this.setState({ date })
  }

  getOptions = (unit, formatter) => {
    let { date } = this.state

    let options = []

    if (typeof formatter === 'string') {
      let formatString = formatter
      formatter = dateString => moment(dateString).format(formatString)
    }

    for (let i = -2; i <= 2; i += 1) {
      let dateString = moment.utc(moment(date).add(i, unit)).format()

      options.push({
        label: formatter(dateString),
        value: dateString
      })
    }

    return options
  }

  getAMPMOptions = () => {
    let { date } = this.state
    let dateObj = moment(date)

    let currentValue = dateObj.format('A')
    let isAM = currentValue === 'AM'
    let padTop = isAM ? 2 : 1
    let options = []

    let amValue = isAM ? date : moment.utc(
      moment(date).subtract(12, 'hours')).format()

    let pmValue = isAM ? moment.utc(
      moment(date).add(12, 'hours')).format() : date

    for (let i = 0; i < padTop; i += 1) {
      options.push({
        disabled: true,
        label: '',
        value: `top-pad-${padTop - i}`
      })
    }

    options.push({ label: 'AM', value: amValue })
    options.push({ label: 'PM', value: pmValue })

    for (let i = 0; i < 3 - padTop; i += 1) {
      options.push({
        disabled: true,
        label: '',
        value: `bottom-pad-${i}`
      })
    }

    return options
  }

  handlePressOk = () => {
    let { date } = this.state
    let { onChangeDate } = this.props

    onChangeDate(date)
  }

  renderSelects = () => {
    let { mode } = this.props

    if (mode === 'date') {
      return (
        <div className="date-picker-modal-selects">
          <Select
            options={this.getOptions('month', 'MMMM')}
            onChange={this.handleSelectChange}
          />
          <Select
            options={this.getOptions('day', 'D')}
            onChange={this.handleSelectChange}
          />
          <Select
            options={this.getOptions('year', 'YYYY')}
            onChange={this.handleSelectChange}
          />
        </div>
      )
    }

    return (
      <div className="date-picker-modal-selects">
        <Select
          options={this.getOptions('day', dateFriendly)}
          onChange={this.handleSelectChange}
        />
        <Select
          numeric
          options={this.getOptions('hour', 'h')}
          onChange={this.handleSelectChange}
        />
        <Select
          numeric
          options={this.getOptions('minute', 'mm')}
          onChange={this.handleSelectChange}
        />
        <Select
          options={this.getAMPMOptions()}
          onChange={this.handleSelectChange}
        />
      </div>
    )
  }

  render() {
    let { onCancel } = this.props

    return (
      <div className="date-picker-modal-wrapper">
        <div
          className="date-picker-modal-backdrop"
          onClick={onCancel}
        />
        <div className="date-picker-modal">
          <div className="date-picker-modal-actions">
            <div className="date-picker-modal-cancel" onClick={onCancel}>
              Cancel
            </div>
            <div className="date-picker-modal-ok" onClick={this.handlePressOk}>
              OK
            </div>
          </div>
          {this.renderSelects()}
        </div>
      </div>
    )
  }
}
