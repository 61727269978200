import React, { Component } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'

import { isMobileBrowser } from './utils/browsers'
import PickerModal from './PickerModal'
import ModalWrapper from './ModalWrapper'

export default class DateInput extends Component {
  static propTypes = {
    date: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object
    ]),
    mode: PropTypes.oneOf(['datetime', 'date']),
    onDateChange: PropTypes.func,
    customStyles: PropTypes.object
  }

  static defaultProps = {
    customStyles: {},
    date: String(new Date()),
    mode: 'datetime',
  }

  state = {
    open: false
  }

  handleOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  handleSelectDate = newDate => {
    let { onDateChange } = this.props

    let dateObj = new Date(newDate)

    if (onDateChange) {
      onDateChange(newDate, dateObj)
    }

    this.handleClose()
  }

  handleChangeInput = e => {
    let value = e.target.value
    if (!value) { return }

    let dateString = moment.utc(moment(value, 'YYYY-MM-DDTHH:mm')).format()

    this.handleSelectDate(dateString)
  }

  getInputValue = () => {
    let { date, mode } = this.props
    let dateObj = moment(date)

    if (mode === 'date') {
      return dateObj.format('YYYY-MM-DD')
    } else {
      return dateObj.format('YYYY-MM-DDTHH:mm')
    }
  }

  getBoxShadow() {
    let { customStyles: { dateInput } } = this.props

    if (dateInput) {
      let shadowRadius = dateInput.shadowRadius || 0
      let shadowColor = dateInput.shadowColor || '#000'
      let shadowOffset = dateInput.shadowOffset || [0, 0]
      let x = shadowOffset.width || 0
      let y = shadowOffset.height || 0

      if (!x && !y && !shadowRadius) { return }

      return `${x}px ${y}px ${shadowRadius}px ${shadowColor}`
    }
  }

  getInputType = () => {
    let { mode } = this.props

    return mode === 'date' ? 'date' : 'datetime-local'
  }

  render() {
    let { open } = this.state
    let { date, customStyles, mode, format } = this.props
    let dateObj = moment(date)
    date = moment.utc(date).format()

    let borderStyle = (
      customStyles.dateInput &&
      customStyles.dateInput.borderWidth
    ) ? 'solid' : 'none'

    let boxShadow = this.getBoxShadow()

    if (!format) {
      format = mode === 'date' ? 'dddd, ll' : 'llll'
    }

    let styles = { ...customStyles.dateInput, borderStyle, boxShadow }

    if (isMobileBrowser()) {
      if (styles.height) {
        let lineHeight = styles.height - (styles.borderWidth || 0)
        styles.lineHeight = `${lineHeight}px`
      }

      let {
        flex,
        marginTop,
        marginLeft,
        marginRight,
        marginBottom,
        width,
        ...inputStyles
      } = styles

      let wrapperStyles = {
        flex,
        marginTop,
        marginLeft,
        marginRight,
        marginBottom,
        width,
      }

      let inputType = this.getInputType()
      let inputValue = this.getInputValue()

      return (
        <div
          className="date-picker-native-input-wrapper"
          style={wrapperStyles}
        >
          <input
            className="date-picker-native-input"
            type={inputType}
            value={inputValue}
            onChange={this.handleChangeInput}
            style={inputStyles}
          />
        </div>
      )
    }

    return (
      <div className="date-picker">
        <div
          className="date-picker-trigger"
          onClick={this.handleOpen}
          style={styles}
        >
          {dateObj.format(format)}
        </div>
        <ModalWrapper>
          <div>
            <CSSTransition
              unmountOnExit
              in={open}
              classNames="date-picker"
              timeout={300}
            >
              {state => (
                <PickerModal
                  mode={mode}
                  date={date}
                  onChangeDate={this.handleSelectDate}
                  onCancel={this.handleClose}
                />
              )}
            </CSSTransition>
          </div>
        </ModalWrapper>
      </div>
    )
  }
}
