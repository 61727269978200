import React, { Component } from 'react'
import { View, Platform, StyleSheet } from 'react-native'

export default class Column extends Component {
  render() {
    const {
      children,
      object: { layout },
    } = this.props

    const environment = {}

    if (Platform.OS === 'web') {
      environment.minHeight = 'min-content'
    }

    return (
      <View
        style={[styles.wrapper, layout, environment]}
        pointerEvents="box-none"
      >
        {children}
      </View>
    )
  }
}

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
})
