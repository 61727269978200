import { TLocalizedMessages } from './types'

export const removeMessages: TLocalizedMessages = {
  en: {
    body: 'Remove',
  },
  es: {
    body: 'Eliminar',
  },
  fr: {
    body: 'Retirer',
  },
  it: {
    body: 'Rimuovere',
  },
  ja: {
    body: '削除',
  },
  ko: {
    body: '삭제',
  },
  pt: {
    body: 'Remover',
  },
  ru: {
    body: 'Удалить',
  },
  zh: {
    body: '移除',
  },
  de: {
    body: 'Entfernen',
  },
  hi: {
    body: 'हटाएँ',
  },
  ar: {
    body: 'إزالة',
  },
  he: {
    body: 'הסר',
  },
  id: {
    body: 'Hapus',
  },
}
