/* eslint-disable max-classes-per-file */
import { actionTypes } from '@adalo/constants'

// Gets flat list of sources from actions
// Expands formatted text
export const getAllSources = actions => {
  let sources = []

  Object.keys(actions).forEach(objectId => {
    Object.keys(actions[objectId]).forEach(key => {
      const actionChain = actions[objectId][key].actions

      actionChain.forEach(actionObj => {
        sources = sources.concat(getActionSources(actionObj))
      })
    })
  })

  return flattenSourceList(sources)
}

export const flattenSourceList = sources => {
  let results = []

  sources.forEach(([key, source]) => {
    if (Array.isArray(source)) {
      results = results.concat(
        flattenSourceList(source.map((itm, i) => [`${key}.${i}`, itm]))
      )
    } else if (source && source.type === 'formula') {
      results = results.concat(
        flattenSourceList(source.formula.map((itm, i) => [`${key}.${i}`, itm]))
      )
    } else if (source && typeof source === 'object') {
      results.push([key, source])
    }
  })

  return results
}

export const getActionSources = actionObj => {
  const action = buildAction(actionObj)
  return action.getSources()
}

class BaseAction {
  constructor(actionObj) {
    this._action = actionObj
    this._options = actionObj && actionObj.options
  }

  getSources() {
    const result = []

    if (this._options && this._options.conditional) {
      result.push(['conditional', this._options.conditional])
      result.push([
        'conditionalComparison',
        this._options.conditional.comparison,
      ])
      result.push([
        'conditionalComparison2',
        this._options.conditional.comparison2,
      ])

      const { comparatorOptions } = this._options.conditional
      if (comparatorOptions) {
        // Every option is potentially a dependency
        for (const key of Object.keys(comparatorOptions)) {
          result.push([`comparatorOptions.${key}`, comparatorOptions[key]])
        }
      }
    }

    if (!this._options) return result
    return result.concat(this.getSourcesSub())
  }

  getSourcesSub() {
    return []
  }
}

// Delete
class ReferenceAction extends BaseAction {
  getSourcesSub() {
    const result = [['bindingId', this._action.options.bindingId]]

    return result
  }
}

// Create, Update
class FieldsAction extends BaseAction {
  getSourcesSub() {
    const result = []

    if (
      this._action.actionType === actionTypes.UPDATE_OBJECT &&
      this._options.bindingId
    ) {
      result.push(['bindingId', this._options.bindingId])
    }

    if (!this._options.fields) {
      return result
    }

    this._options.fields.forEach(field => {
      if (
        field &&
        field.source !== undefined &&
        field.source !== '' &&
        field.fieldId
      ) {
        result.push([`fields.${field.fieldId}`, field.source])
      }
    })

    return result
  }
}

// Sign In
class Authenticate extends BaseAction {
  getSourcesSub() {
    const keys = ['username', 'email', 'password']
    const result = []

    keys.forEach(field => {
      const source = this._options[field]

      if (source) {
        result.push([field, source])
      }
    })

    return result
  }
}

// Navigate
class Navigate extends BaseAction {
  getSourcesSub() {
    const params = this._options.params || {}
    const result = []

    for (const paramId in params) {
      const source = params[paramId]

      if (paramId && source) {
        result.push([`params.${paramId}`, source])
      }
    }

    return result
  }
}

class ExternalLink extends BaseAction {
  getSourcesSub() {
    const { url } = this._options

    if (!url) {
      return []
    }

    return [['url', url]]
  }
}

// Add to collection, remove from collection
class Association extends BaseAction {
  getSourcesSub() {
    const { object1, object2, fieldId } = this._options

    if (!object1 || !object2 || !fieldId) {
      return []
    }

    return [
      ['object1', this._options.object1],
      ['object2', this._options.object2],
      ['fieldId', this._options.fieldId],
    ]
  }
}

// Share / Set input value
class SingleSourceAction extends BaseAction {
  getSourcesSub() {
    const { source } = this._options

    return [['source', source]]
  }
}

// Notifications
class NotificationAction extends BaseAction {
  getSourcesSub() {
    let { audience, title, body, screenId, params } = this._options

    if (!audience || !screenId || (!title && !body)) {
      return []
    }

    params = params || {}
    const result = []

    for (const paramId in params) {
      const source = params[paramId]

      if (paramId && source) {
        result.push([`params.${paramId}`, source])
      }
    }

    return result.concat([
      ['audience', audience],
      ['title', title],
      ['body', body],
      ['screenId', screenId],
    ])
  }
}

// APIs
class APIAction extends BaseAction {
  getSourcesSub() {
    const params = this._options.params || {}
    const result = []

    for (const key in params) {
      result.push([`params.${key}`, params[key]])
    }

    return result
  }
}

class CustomAction extends BaseAction {
  getSourcesSub() {
    const result = []
    const inputs = this._options.source

    for (const itm in inputs) {
      result.push([itm, inputs[itm]])
    }

    return result
  }
}

const classMap = {
  // Delete
  [actionTypes.DELETE_OBJECT]: ReferenceAction,

  // Create, Update, Signup
  [actionTypes.CREATE_OBJECT]: FieldsAction,
  [actionTypes.UPDATE_OBJECT]: FieldsAction,
  [actionTypes.SIGNUP]: FieldsAction,

  // Login
  [actionTypes.AUTHENTICATE]: Authenticate,

  // Link
  [actionTypes.NAVIGATE]: Navigate,
  [actionTypes.EXTERNAL_LINK]: ExternalLink,

  // Add-to-collection, Remove-from-collection
  [actionTypes.CREATE_ASSOCIATION]: Association,
  [actionTypes.DELETE_ASSOCIATION]: Association,

  // Share, Set Input Value
  [actionTypes.SHARE]: SingleSourceAction,
  [actionTypes.SET_INPUT_VALUE]: SingleSourceAction,

  // Push Notifications
  [actionTypes.PUSH_NOTIFICATION]: NotificationAction,

  // APIs
  [actionTypes.CALL_API]: APIAction,
  [actionTypes.AUTHENTICATE_API]: APIAction,
  [actionTypes.SIGNUP_API]: APIAction,

  [actionTypes.CUSTOM]: CustomAction,
  [actionTypes.AUTHENTICATE_EXTERNAL]: CustomAction,
  [actionTypes.SIGNUP_EXTERNAL]: CustomAction,
}

export const buildAction = actionObj => {
  const ActionClass = classMap[actionObj.actionType] || BaseAction

  return new ActionClass(actionObj)
}
