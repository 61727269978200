import { sortDirections, dataTypes } from '@adalo/constants'
import { kilometersBetweenLocations } from './location'
import { getBindingValue } from './dependencies'

export const applySort = (data, sort, opts = null) => {
  if (!sort || !Array.isArray(data)) {
    return data
  }
  const { reference } = sort
  const { source, fallback } = reference || {}

  const { fieldId, direction } = sort

  const bias = direction === sortDirections.ASC ? 1 : -1

  let getValue = a => a[fieldId]

  if (sort.type === dataTypes.LOCATION && reference) {
    let sortReferenceValue = getBindingValue(source, opts)
    if (!sortReferenceValue) {
      sortReferenceValue = fallback
    }
    if (sortReferenceValue) {
      getValue = a => {
        if (a[fieldId] && a[fieldId].coordinates) {
          return kilometersBetweenLocations(
            a[fieldId].coordinates,
            sortReferenceValue.coordinates
          )
        }
        return -1
      }
    }
  }

  return data.slice().sort((a, b) => {
    if (a.id === b.id) {
      return 0
    }

    let valueA = getValue(a)
    let valueB = getValue(b)

    if (typeof valueA === 'string') {
      valueA = valueA.toLowerCase()
    }
    if (typeof valueB === 'string') {
      valueB = valueB.toLowerCase()
    }

    const value = valueA > valueB ? 1 : -1

    return bias * value
  })
}
