import { IObject } from 'interfaces'

export const ASPECT_RATIO = 2 / 3
export const MAX_HEIGHT = 220

function determineImagePickerHeight(object?: IObject): number {
  const width: number | undefined = object?.attributes?.width

  return width
    ? Math.min(Math.round(width * ASPECT_RATIO), MAX_HEIGHT)
    : MAX_HEIGHT
}

export default determineImagePickerHeight
