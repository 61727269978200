import AsyncStorage from '@react-native-async-storage/async-storage'

import { request } from './networking'
import { scheduleRecurringTask } from './taskScheduler'
import { buildURL } from './urls'

export const getHttpMethod = type => {
  switch (type) {
    case 'update':
      return 'put'
    default:
      return type
  }
}

export const requiresValidation = opts => {
  const { object } = opts

  if ('email' in object || 'username' in object || 'password' in object) {
    return true
  }

  return false
}

const getKey = opts => {
  const { type, baseURL, datasourceId, tableId, objectId } = opts

  return [baseURL, type, datasourceId, tableId, objectId].join('.')
}

class SaveQueue {
  requests = {}

  constructor() {
    scheduleRecurringTask(this.run, 5000)

    this.initializeRequests()
  }

  initializeRequests = async () => {
    const requestsJSON = await AsyncStorage.getItem('request-queue')
    const requests = requestsJSON && JSON.parse(requestsJSON)

    if (requests) {
      this.requests = { ...this.requests, ...requests }
    }
  }

  isPendingDelete = (tableId, objectId) => {
    for (const key of Object.keys(this.requests)) {
      const { type, tableId: tid, objectId: oid } = this.requests[key]

      if (type === 'delete' && tableId === tid && objectId === oid) {
        return true
      }
    }

    return false
  }

  storeQueueToDisk = () => {
    AsyncStorage.setItem('request-queue', JSON.stringify(this.requests))
  }

  push = (type, opts) => {
    const { object } = opts
    const payload = { ...opts, type }
    const key = getKey(payload)

    if (this.requests[key] && type === 'update') {
      this.requests[key] = {
        ...this.requests[key],
        object: {
          ...this.requests[key].object,
          ...object,
        },
      }
    } else {
      this.requests[key] = payload
    }

    this.storeQueueToDisk()
  }

  run = async () => {
    try {
      if (Object.keys(this.requests).length === 0) return

      console.log(this.requests)

      const promises = Object.keys(this.requests).map(k =>
        this.makeRequest(k, this.requests[k])
      )

      await Promise.all(promises)
    } catch (err) {
      console.error('ERROR SAVING...', err)
    }

    this.storeQueueToDisk()
  }

  makeRequest = async (key, opts) => {
    const { type, baseURL, datasourceId, tableId, objectId, object } = opts

    const data = type === 'delete' ? undefined : object

    await request(
      datasourceId,
      buildURL(baseURL, { datasourceId, tableId, id: objectId }),
      getHttpMethod(type),
      data
    ).catch(err => {
      if (!err.response) {
        throw err
      } else {
        console.error('Request failed:', err)
      }
    })

    delete this.requests[key]
  }
}

export default new SaveQueue()
