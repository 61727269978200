import { Alert } from './alerts.web'
import { getLocalizedText, fileTooLargeMessages } from './languageLocale'
import { dataLimit } from './numbers'

export class ImagePicker {
  static showImagePicker = (opts, callback) => {
    let hasCleanedUp = false
    let changed = false
    const input = document.createElement('input')
    input.type = 'file'
    input.pointerEvents = 'none'
    input.style.position = 'fixed'
    input.style.zIndex = 10000
    input.style.top = '-200px'
    input.style.left = '20px'

    if (opts && !opts.acceptAllFiles) {
      input.accept = 'image/*'
    }

    document.body.appendChild(input)

    const cleanup = () => {
      if (hasCleanedUp) {
        return
      }
      hasCleanedUp = true

      input.removeEventListener('change', changeListener)
      input.removeEventListener('focus', focusHandler)
      document.body.removeChild(input)
    }

    const changeListener = e => {
      const file = e.target.files[0]
      changed = true
      if (file) {
        if (file.size < dataLimit) {
          const reader = new FileReader()

          reader.onload = () => {
            const uri = reader.result
            const data = uri.split(',')[1]
            const filename = file.name

            callback({
              uri,
              data,
              filename,
            })

            window.setTimeout(cleanup, 0)
          }

          reader.readAsDataURL(file)
        } else {
          const { title, body } = getLocalizedText(fileTooLargeMessages)
          Alert.alert(title, body)
          cleanup()
        }
      } else {
        cleanup()
      }
    }

    const focusHandler = () => {
      window.setTimeout(() => {
        if (changed) {
          return
        }
        cleanup()
      }, 1000)
    }

    input.addEventListener('change', changeListener)

    window.setTimeout(() => {
      input.focus()

      input.addEventListener('focus', focusHandler)

      input.click()
    }, 0)
  }
}

export const FilePicker = {
  showFilePicker: callback => {
    return ImagePicker.showImagePicker({ acceptAllFiles: true }, callback)
  },
}
