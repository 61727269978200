export const BackHandler = {
  addEventListener: (type, callback) => {
    if (type === 'hardwareBackPress') {
      window.addEventListener('popstate', callback)
    }
  },

  removeEventListener: (type, callback) => {
    if (type === 'hardwareBackPress') {
      window.removeEventListener('popstate', callback)
    }
  },
}
