import { Buffer } from 'buffer/'
import { isEmpty } from './objects'

export const getPayload = jwt => {
  if (!jwt || isEmpty(jwt)) {
    return null
  }

  const pieces = jwt.split('.')
  const payload = JSON.parse(Buffer.from(pieces[1], 'base64'))

  let { exp } = payload
  exp *= 1000

  if (exp && exp < +new Date()) {
    return null
  }

  return payload
}

export const fakeJWT = payload => {
  const header = { typ: 'jwt', alg: 'HS256' }

  let encodedHeader = Buffer.from(JSON.stringify(header)).toString('base64')
  let encodedPayload = Buffer.from(JSON.stringify(payload)).toString('base64')
  const signature = 'asdf'

  encodedHeader = encodedHeader.replace(/=/g, '')
  encodedPayload = encodedPayload.replace(/=/g, '')

  return `${encodedHeader}.${encodedPayload}.${signature}`
}
