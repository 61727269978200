export const compareFormValues = (value1, value2) => {
  if (typeof value1 === 'object' && typeof value2 === 'object') {
    return (
      value1.filename === value2.filename &&
      value1.url === value2.url &&
      value1.size === value2.size
    )
  } else {
    return value1 === value2
  }
}
