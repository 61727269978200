import React, { Component } from 'react'
import { View } from 'react-native'

export default class PseudoKeyboardAvoidingView extends Component {
  render() {
    const { children, style, pointerEvents } = this.props

    return (
      <View style={style} pointerEvents={pointerEvents}>
        {children}
      </View>
    )
  }
}
