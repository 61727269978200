import axios from 'axios'
import deepEqual from 'deep-equal'

const CHANGE_VALUE = Symbol('CHANGE_VALUE')
const SET_DEFAULT = Symbol('SET_DEFAULT')
const RESET_VALUE = Symbol('RESET_VALUE')
const SET_MULTIPLE = Symbol('SET_MULTIPLE')
const UPLOAD_IMAGE = 'UPLOAD_IMAGE'
const UPLOAD_FILE = 'UPLOAD_FILE'

const INITIAL_STATE = { values: {}, defaultValues: {} }

// REDUCER

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = INITIAL_STATE, action) => {
  if (action.type === CHANGE_VALUE) {
    return {
      ...state,
      values: {
        ...state.values,
        [action.objectId]: action.value,
      },
    }
  }

  if (action.type === SET_DEFAULT) {
    const { objectId, value } = action
    const prevValue = state.defaultValues[objectId]

    if (deepEqual(prevValue, value)) {
      return state
    }

    return {
      ...state,
      defaultValues: {
        ...state.defaultValues,
        [objectId]: value,
      },
      values: {
        ...state.values,
        [objectId]: value,
      },
    }
  }

  if (action.type === RESET_VALUE) {
    state = { ...state, values: { ...state.values } }

    delete state.values[action.objectId]
    delete state.defaultValues[action.objectId]

    return state
  }

  if (action.type === SET_MULTIPLE) {
    return {
      ...state,
      values: {
        ...state.values,
        ...action.values,
      },
    }
  }

  // Images

  if (action.type === `${UPLOAD_IMAGE}_PENDING`) {
    const { objectId, data } = action.meta

    return {
      ...state,
      values: {
        ...state.values,
        [objectId]: { _pending: true, preview: data },
      },
    }
  }

  if (action.type === `${UPLOAD_IMAGE}_FULFILLED`) {
    const { payload } = action
    const { objectId } = action.meta

    return {
      ...state,
      values: {
        ...state.values,
        [objectId]: payload.data,
      },
    }
  }

  if (action.type === `${UPLOAD_IMAGE}_REJECTED`) {
    console.log('UPLOAD ERROR:', action)
  }

  // Files

  if (action.type === `${UPLOAD_FILE}_FULFILLED`) {
    const { payload } = action
    const { request, objectId } = payload

    return {
      ...state,
      values: {
        ...state.values,
        [objectId]: request.data,
      },
    }
  }

  if (action.type === `${UPLOAD_FILE}_REJECTED`) {
    console.log('FILE UPLOAD ERROR:', action)
  }

  return state
}

// ACTIONS

export const changeValue = (objectId, value) => ({
  type: CHANGE_VALUE,
  objectId,
  value,
})

export const setDefaultValue = (objectId, value) => ({
  type: SET_DEFAULT,
  objectId,
  value,
})

export const resetValue = objectId => ({
  type: RESET_VALUE,
  objectId,
})

export const setValues = values => ({
  type: SET_MULTIPLE,
  values,
})

export const uploadImage = (baseURL, objectId, filename, data, dataURL) => ({
  type: UPLOAD_IMAGE,
  payload: axios.post(`${baseURL}/uploads`, { filename, data }),
  meta: {
    data: dataURL,
    objectId,
  },
})

export const uploadFile = (baseURL, objectId, filename, data) => ({
  type: UPLOAD_FILE,
  payload: async () => ({
    objectId,
    request: await axios.post(`${baseURL}/uploads`, { filename, data }),
  }),
})

// SELECTORS

export const getValue = (state, objectId) => {
  const value = getRawValue(state, objectId)

  // For image uploads
  if (value && value.preview) {
    return value.preview
  }

  // Image uploads, in cases where the preview does not get set
  if (value && value._pending === false && value.value) {
    return value.value
  }

  return value
}

export const getFinalValue = (state, objectId) => {
  const value = getRawValue(state, objectId)

  if (value && value._pending) {
    return null
  } else if (value && value.value) {
    return value.value
  }

  return value
}

export const getRawValue = (state, objectId) => {
  return state.formInputs.values[objectId]
}

export const getFormInputs = state => {
  return state.formInputs.values
}
