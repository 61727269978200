import immutabilityHelper from 'immutability-helper'
import QS from 'qs'

const buildQuery = routeStack => {
  const head = routeStack[0] || {}
  let { target, params } = head
  const existingParams = QS.parse(window.location.search.slice(1))

  params = JSON.stringify(params || {})

  return `?${QS.stringify({ ...existingParams, target, params })}`
}

export const createStack = (...vals) => {
  let state = { routeStack: vals }
  const query = QS.parse(window.location.search.substring(1))
  let replace = false
  const isPWA =
    window.navigator.standalone ||
    window.matchMedia('(display-mode: standalone)').matches

  // addresses routing issue with PWAs
  if (isPWA) window.history.replaceState({ routeStack: vals }, null)

  if (window.history.state && window.history.state.routeStack) {
    vals = window.history.state.routeStack
  } else if (query.target) {
    let params = {}

    try {
      params = JSON.parse(query.params || '{}')
    } catch (err) {
      console.log('ERROR PARSING PARAMS:', query.params)
    }

    const { target } = query

    vals = [{ target, params }, ...vals]
    state = { routeStack: vals }
    replace = true
  } else {
    replace = true
  }

  if (replace) {
    const query = buildQuery(vals)
    window.history.replaceState(state, null, query)
  }

  return vals
}

export const push = (stack, newValue, navigate = true) => {
  const routeStack = immutabilityHelper(stack, {
    $splice: [[0, 0, newValue]],
  })

  const query = buildQuery(routeStack)

  if (navigate) {
    window.history.pushState({ routeStack }, null, query)
  } else {
    window.history.replaceState({ routeStack }, null, query)
  }

  return routeStack
}

export const peek = (stack, offset = 0) => {
  return stack[offset]
}

export const pop = (stack, replace = false) => {
  const head = stack[0]

  const newStack = immutabilityHelper(stack, {
    $splice: [[0, 1]],
  })

  const state = (window.history.state && window.history.state.routeStack) || []
  const stateHead = state[0] || {}
  const newHead = peek(newStack) || {}

  if (stateHead.target !== newHead.target) {
    window.history.pushState({ routeStack: newStack }, null)
  }

  return [head, newStack]
}
