/* eslint-disable max-classes-per-file */
import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  View,
  Text,
  StyleSheet,
  StatusBar,
  Animated,
  TouchableWithoutFeedback,
} from 'react-native'

import {
  getCurrentNotification,
  clickNotification,
  hideNotification,
} from '../ducks/notifications'

class NotificationItem extends Component {
  state = {
    position: new Animated.Value(-100),
  }

  handlePress = () => {
    const { position } = this.state
    const { clickNotification } = this.props

    Animated.timing(position, {
      toValue: -100,
      duration: 200,
      useNativeDriver: true,
    }).start(() => {
      clickNotification()
    })
  }

  componentDidMount() {
    const { position } = this.state
    const { hideNotification } = this.props

    Animated.timing(position, {
      toValue: 0,
      duration: 500,
      useNativeDriver: true,
    }).start()

    this.timer = window.setTimeout(() => {
      Animated.timing(position, {
        toValue: -100,
        useNativeDriver: true,
      }).start(() => {
        hideNotification()
      })
    }, 5000)
  }

  componentWillUnmount() {
    window.clearTimeout(this.timer)
  }

  render() {
    const { position } = this.state
    const { notification } = this.props
    const { title, body } = notification

    const paddingStyles = {
      paddingTop: 40,
    }

    return (
      <Animated.View style={{ transform: [{ translateY: position }] }}>
        <TouchableWithoutFeedback onPress={this.handlePress}>
          <View style={[styles.notification, paddingStyles]}>
            {title && <Text style={styles.title}>{title}</Text>}
            {body && <Text style={styles.body}>{body}</Text>}
            <StatusBar barStyle="dark-content" />
          </View>
        </TouchableWithoutFeedback>
      </Animated.View>
    )
  }
}

class Notifications extends Component {
  render() {
    const { notification, clickNotification, hideNotification } = this.props

    if (!notification) {
      return null
    }

    return (
      <>
        {[
          <NotificationItem
            key={`${notification.id}`}
            notification={notification}
            clickNotification={clickNotification}
            hideNotification={hideNotification}
          />,
        ]}
      </>
    )
  }
}

const styles = StyleSheet.create({
  notification: {
    backgroundColor: '#fff',
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 16,
    paddingRight: 16,
    elevation: 8,
  },
  title: {
    fontSize: 16,
    fontWeight: '600',
  },
  body: {
    fontSize: 14,
    fontWeight: '400',
    color: '#666',
  },
})

const mapStateToProps = state => ({
  notification: getCurrentNotification(state),
})

export default connect(mapStateToProps, {
  clickNotification,
  hideNotification,
})(Notifications)
