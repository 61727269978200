import { IReduxState } from 'interfaces'
import axios from 'axios'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import {
  SET_CURRENT_LOCATION,
  DEVICE_LOCATION_REJECTED,
  API_LOCATION_REJECTED,
} from 'ducks/location'
import { IGeolocationPosition } from './types'

const getCurrentPosition = async (): Promise<{
  coords: IGeolocationPosition
}> => {
  return new Promise((resolve, reject) => {
    window.navigator.geolocation.getCurrentPosition(resolve, reject)
  })
}

export const getDeviceLocation = async (
  dispatch: ThunkDispatch<IReduxState, unknown, AnyAction>,
  requestUrl: string,
  appId: string,
  language: string
): Promise<void> => {
  let coords

  try {
    ;({ coords } = await getCurrentPosition())
  } catch (err) {
    dispatch({
      type: DEVICE_LOCATION_REJECTED,
      payload: err,
    })
    return
  }

  try {
    const { latitude, longitude } = coords

    const { data } = await axios.get(requestUrl, {
      params: { latitude, longitude, appId, language },
    })

    dispatch({
      type: SET_CURRENT_LOCATION,
      payload: data,
    })
  } catch (err: any) {
    dispatch({
      type: API_LOCATION_REJECTED,
      payload: {
        message: err.response.data.message,
        code: err.response.status,
      },
    })
  }
}
