import { TLocalizedMessages } from './types'

export const cameraPermissionsMessages: TLocalizedMessages = {
  en: {
    title: 'Camera Access Denied',
    body: 'Please change your device settings to allow camera access',
  },
  es: {
    title: 'Acceso a la cámara denegado',
    body: 'Cambie la configuración de su dispositivo para permitir el acceso a la cámara',
  },
  fr: {
    title: 'Accès à la caméra refusé',
    body: "Veuillez modifier les paramètres de votre appareil pour autoriser l'accès à la caméra",
  },
  ja: {
    title: 'カメラアクセスが拒否されました',
    body: 'カメラアクセスを許可するようにデバイス設定を変更してください',
  },
  zh: {
    title: '相机访问被拒绝',
    body: '请更改您的设备设置以允许访问相机',
  },
  ru: {
    title: 'Доступ к камере запрещен',
    body: 'Измените настройки устройства, чтобы разрешить доступ к камере',
  },
  de: {
    title: 'Kamerazugriff verweigert',
    body: 'Bitte ändern Sie Ihre Geräteeinstellungen, um den Kamerazugriff zuzulassen',
  },
  pt: {
    title: 'Acesso à câmera negado',
    body: 'Altere as configurações do dispositivo para permitir o acesso à câmera',
  },
  hi: {
    title: 'कैमरा एक्सेस अस्वीकृत',
    body: 'कैमरा एक्सेस की अनुमति देने के लिए कृपया अपनी डिवाइस सेटिंग बदलें',
  },
  id: {
    title: 'Akses Kamera Ditolak',
    body: 'Harap ubah pengaturan perangkat Anda untuk mengizinkan akses kamera',
  },
  he: {
    title: 'הקובץ אינו קייםהגישה למצלמה נדחתה',
    body: 'אנא שנה את הגדרות המכשיר שלך כדי לאפשר גישה למצלמה',
  },
  ko: {
    title: '카메라 액세스 거부됨',
    body: '카메라 액세스를 허용하려면 기기 설정을 변경하세요.',
  },
  it: {
    title: 'Accesso alla fotocamera negato',
    body: "Si prega di modificare le impostazioni del dispositivo per consentire l'accesso alla fotocamera",
  },
  ar: {
    title: 'تم رفض الوصول إلى الكاميرا',
    body: 'يرجى تغيير إعدادات جهازك للسماح بالوصول إلى الكاميرا',
  },
}
