import { TLocalizedMessages } from './types'

export const choosePhotoMessages: TLocalizedMessages = {
  en: {
    body: 'Choose Photo',
  },
  es: {
    body: 'Escoge una foto',
  },
  fr: {
    body: 'Choisissez la photo',
  },
  ja: {
    body: '写真を選択',
  },
  zh: {
    body: '选择照片',
  },
  ru: {
    body: 'Выбрать фото',
  },
  de: {
    body: 'Wähle ein Foto aus',
  },
  pt: {
    body: 'Escolher Foto',
  },
  hi: {
    body: 'तस्विर का चयन करो',
  },
  id: {
    body: 'Pilih foto',
  },
  he: {
    body: 'בחר תמונה',
  },
  ko: {
    body: '사진 선택',
  },
  it: {
    body: 'Scegli la foto',
  },
  ar: {
    body: 'اختر الصورة',
  },
}

export const changePhotoMessages: TLocalizedMessages = {
  en: {
    body: 'Change Photo',
  },
  es: {
    body: 'Cambiar foto',
  },
  fr: {
    body: 'Changer la photo',
  },
  ja: {
    body: '写真を変更',
  },
  zh: {
    body: '更改照片',
  },
  ru: {
    body: 'Изменить фото',
  },
  de: {
    body: 'Foto ändern',
  },
  pt: {
    body: 'Mudar Foto',
  },
  hi: {
    body: 'तस्विर बदलो',
  },
  id: {
    body: 'Ganti foto',
  },
  he: {
    body: 'שנה תמונה',
  },
  ko: {
    body: '사진 변경',
  },
  it: {
    body: 'Cambia la foto',
  },
  ar: {
    body: 'تغيير الصورة',
  },
}

export const takePhotoMessages: TLocalizedMessages = {
  en: {
    body: 'Take Photo',
  },
  es: {
    body: 'Tomar foto',
  },
  fr: {
    body: 'Prendre une photo',
  },
  ja: {
    body: '写真を撮る',
  },
  zh: {
    body: '拍照',
  },
  ru: {
    body: 'Сделать фото',
  },
  de: {
    body: 'Foto aufnehmen',
  },
  pt: {
    body: 'Tirar Foto',
  },
  hi: {
    body: 'तस्विर ले',
  },
  id: {
    body: 'Ambil foto',
  },
  he: {
    body: 'צלם תמונה',
  },
  ko: {
    body: '사진 찍기',
  },
  it: {
    body: 'Scatta la foto',
  },
  ar: {
    body: 'اتخاذ صورة',
  },
}

export const chooseFromLibraryMessages: TLocalizedMessages = {
  en: {
    body: 'Choose From Library',
  },
  es: {
    body: 'Escoge de la biblioteca',
  },
  fr: {
    body: 'Choisir dans la bibliothèque',
  },
  ja: {
    body: 'ライブラリから選択',
  },
  zh: {
    body: '从图库中选择',
  },
  ru: {
    body: 'Выбрать из библиотеки',
  },
  de: {
    body: 'Aus der Bibliothek auswählen',
  },
  pt: {
    body: 'Escolher da Biblioteca',
  },
  hi: {
    body: 'पुस्तकालय से चुनें',
  },
  id: {
    body: 'Pilih dari Bibliotek',
  },
  he: {
    body: 'בחר מהספריה',
  },
  ko: {
    body: '라이브러리에서 선택',
  },
  it: {
    body: 'Scegli dalla libreria',
  },
  ar: {
    body: 'اختر من المكتبة',
  },
}

export const youCanChooseOneImageMessages: TLocalizedMessages = {
  en: {
    body: 'You can choose one image',
  },
  es: {
    body: 'Puedes escoger una imagen',
  },
  fr: {
    body: 'Vous pouvez choisir une image',
  },
  ja: {
    body: '写真を1枚まで選択できます',
  },
  zh: {
    body: '你可以选择一张图片',
  },
  ru: {
    body: 'Вы можете выбрать одно изображение',
  },
  de: {
    body: 'Du kannst nur ein Bild auswählen',
  },
  pt: {
    body: 'Você pode escolher uma imagem',
  },
  hi: {
    body: 'आप एक तस्वीर का चयन कर सकते हैं',
  },
  id: {
    body: 'Anda dapat memilih satu gambar',
  },
  he: {
    body: 'אתה יכול לבחור אחת מהתמונות',
  },
  ko: {
    body: '사진을 1장 선택 할 수 있습니다',
  },
  it: {
    body: 'Puoi scegliere una foto',
  },
  ar: {
    body: 'يمكنك اختيار صورة واحدة',
  },
}

export const photoVideoMessages: TLocalizedMessages = {
  en: {
    body: 'Photo/Video',
  },
  es: {
    body: 'Foto/Vídeo',
  },
  fr: {
    body: 'Photo/Vidéo',
  },
  ja: {
    body: '写真/ビデオ',
  },
  zh: {
    body: '照片/视频',
  },
  ru: {
    body: 'Фото/видео',
  },
  de: {
    body: 'Foto/Video',
  },
  pt: {
    body: 'Foto/Vídeo',
  },
  hi: {
    body: 'तस्वीर/वीडियो',
  },
  id: {
    body: 'Foto/Video',
  },
  he: {
    body: 'תמונה/וידאו',
  },
  ko: {
    body: '사진/비디오',
  },
  it: {
    body: 'Foto/Video',
  },
  ar: {
    body: 'صورة/فيديو',
  },
}
