import { dataTypes, sourceTypes, selectors } from '@adalo/constants'

import selectorFuncs from './selectors'
import { getRootSource, isChainedRelations } from './includes'

const aggregateTypesMap = {
  [sourceTypes.COUNT]: true,
  [sourceTypes.AVERAGE]: true,
  [sourceTypes.SUM]: true,
  [sourceTypes.MIN]: true,
  [sourceTypes.MAX]: true,
  [sourceTypes.MIN_MAX]: true,
}

export const isAggregateType = type => {
  return !!aggregateTypesMap[type]
}

export const getId = (source, opts) => {
  if (
    source &&
    source.type === sourceTypes.PARAM &&
    source.dataType === dataTypes.OBJECT
  ) {
    return (opts.getParams() || {})[source.paramId]
  }

  if (!source || source.source || !source.selector) {
    return null
  }

  const selectorFunc = selectorFuncs[source.selector.type]

  if (!selectorFunc) {
    throw new Error(`INVALID SELECTOR: ${JSON.stringify(source.selector)}`)
  }

  return selectorFunc(source, opts)
}

export const isInsideList = (source, includeCurrentSource = false) => {
  if (!source) {
    return false
  }

  if (
    includeCurrentSource &&
    source.type === sourceTypes.DATA &&
    source.selector &&
    source.selector.type === selectors.LIST_ITEM
  ) {
    return true
  }

  return isInsideList(source.source, true)
}

// Should return true if there is a list source that's not inside another list
export const shouldHaveCollection = source => {
  if (!source || Array.isArray(source) || typeof source !== 'object') {
    return false
  }

  if (source.source && isAggregateType(source.type)) {
    source = source.source
  }

  const rootSource = getRootSource(source)

  if (!rootSource) {
    return false
  }

  const result =
    rootSource.dataType === dataTypes.LIST ||
    (source.dataType === dataTypes.LIST &&
      isChainedRelations(source) &&
      (!rootSource.selector ||
        rootSource.selector.type !== selectors.LIST_ITEM))

  return result
}

export const getFieldValue = (parentVal, fieldId) => {
  if (!fieldId) {
    return null
  }

  const parts = fieldId.split('.')
  let val = parentVal

  for (const part of parts) {
    val = val && val[part]
  }

  return val
}

export const readBlobAsDataURLAsync = blob => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = () => {
      resolve(reader.result)
    }

    reader.onerror = reject

    reader.readAsDataURL(blob)
  })
}

export const searchForSource = (topObject, targetSourceType) => {
  let result = false

  const deepSearch = object => {
    for (const key of Object.keys(object)) {
      const value = object[key]

      if (value && value.type === targetSourceType) {
        result = true
        break
      }

      if (!result && value && typeof value === 'object') {
        deepSearch(value)
      }
    }
  }

  deepSearch(topObject)

  return result
}
