import { getUserLanguageCode } from './user-locale'
import {
  TLocalizedMessages,
  TLocalizedMessage,
} from './localizedMessages/types'

export {
  closeMessages,
  cancelMessages,
} from './localizedMessages/closeMessages'
export { fileTooLargeMessages } from './localizedMessages/fileTooLargeMessages'
export { missingFileMessages } from './localizedMessages/missingFileMessages'
export { cameraPermissionsMessages } from './localizedMessages/cameraPermissionsMessages'
export {
  chooseFileMessages,
  fileMessages,
  chooseFileTypeMessages,
  errorUploadingFileMessages,
  errorUploadingImageMessages,
} from './localizedMessages/fileMessages'
export { removeMessages } from './localizedMessages/removeMessages'
export {
  choosePhotoMessages,
  changePhotoMessages,
  takePhotoMessages,
  chooseFromLibraryMessages,
  youCanChooseOneImageMessages,
  photoVideoMessages,
} from './localizedMessages/photoMessages'
export { currentLocationMessages } from './localizedMessages/currentLocationMessages'

export const getLocalizedText = (
  messages: TLocalizedMessages
): TLocalizedMessage => {
  const languageCode = getUserLanguageCode()
  return messages[languageCode] || messages.en
}
