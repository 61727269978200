import { coordinateFormats } from '@adalo/constants'

// Resources and coordinate conversion formulae taken from:
// https://en.wikipedia.org/wiki/Geographic_coordinate_conversion
// https://tnp.uservoice.com/knowledgebase/articles/172110-latitude-longitude-formats-and-conversion

// Conversion formulae verified and tested by manual lookups of coordinates:
// https://www.latlong.net/

const getHemisphere = (coordinate, coordinateType) => {
  const coordinateSign = Math.sign(coordinate)

  if (coordinateType === 'longitude') {
    return coordinateSign === 1 ? 'E' : 'W'
  }

  // latitude
  return coordinateSign === 1 ? 'N' : 'S'
}

export const formatCoordinate = (coordinate, field, format) => {
  const hemisphere = getHemisphere(coordinate, field)
  const { type } = format

  const unsignedDegrees = Math.abs(coordinate).toFixed(6)
  const integerPortion = Math.floor(unsignedDegrees)
  const decimalPortion = unsignedDegrees - integerPortion

  switch (type) {
    case coordinateFormats.DECIMAL_DEGREES: {
      return `${unsignedDegrees}° ${hemisphere}`
    }

    case coordinateFormats.DECIMAL_DEGREES_MINUTES: {
      const minutes = (decimalPortion * 60).toFixed(6)

      return `${integerPortion}° ${minutes}' ${hemisphere}`
    }

    case coordinateFormats.DECIMAL_DEGREES_MINUTES_SECONDS: {
      const minutes = Math.floor(60 * decimalPortion)
      const seconds = Math.floor(3600 * decimalPortion - 60 * minutes)

      return `${integerPortion}° ${minutes}' ${seconds}" ${hemisphere}`
    }
    default:
      return coordinate
  }
}
