/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react'

export default class CopyButton extends Component {
  render() {
    const { onHide } = this.props

    return (
      <div style={styles.wrapper}>
        <div style={styles.backdrop} onMouseDown={onHide} />
        <div style={styles.copy} onClick={onHide}>
          Copy
        </div>
        <div style={styles.carrot} />
      </div>
    )
  }
}

// Not StyleSheet because this is DOM not native
const styles = {
  wrapper: {
    position: 'absolute',
    bottom: '100%',
    width: '100%',
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  copy: {
    backgroundColor: '#000',
    color: '#fff',
    paddingBottom: 4,
    paddingTop: 4,
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 5,
    fontSize: 14,
    cursor: 'pointer',
    zIndex: 10001,
    position: 'relative',
  },
  carrot: {
    borderWidth: 8,
    borderBottomWidth: 0,
    borderColor: 'transparent',
    borderTopColor: '#000',
    borderStyle: 'solid',
  },
  backdrop: {
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    zIndex: 10000,
  },
}
