import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { View, StyleSheet } from 'react-native'
import { bindingTypes, dataTypes } from '@adalo/constants'

import { normalizeURL } from '../../utils/urls'
import { evaluateBinding } from '../../utils/dependencies'
import ActionWrapper from '../ActionWrapper'
import BaseObject from '../BaseObject'
import WebView from './WebView'

class WebViewWrapper extends BaseObject {
  static contextTypes = {
    getFileUploadsBaseURL: PropTypes.func,
    getImageUploadsBaseURL: PropTypes.func,
    getFlags: PropTypes.func,
  }

  state = {
    loading: true,
  }

  getURI() {
    const { object, bindingData, uri } = this.props
    const { dataBinding } = object

    if (dataBinding && dataBinding.bindingType === bindingTypes.SET_URI) {
      const uri = bindingData

      const parentSource = dataBinding.source.source || {}

      if (uri && dataBinding.source.isUploadURL) {
        return parentSource.dataType === dataTypes.IMAGE
          ? `${this.context.getImageUploadsBaseURL()}/${uri}?orient`
          : `${this.context.getFileUploadsBaseURL()}/${uri}`
      }

      return uri || ''
    }

    return normalizeURL(uri)
  }

  handleLoad = () => {
    this.setState({ loading: false })
  }

  render() {
    const { component, object, bindingData } = this.props

    const wrapperStyles = {
      ...object.layout,
      height: object.layout.height || object.attributes.height,
    }

    return (
      <ActionWrapper
        bindingData={bindingData}
        component={component}
        object={object}
        style={wrapperStyles}
      >
        <View style={styles.innerWrapper}>
          <WebView
            source={{ uri: this.getURI() }}
            style={styles.webView}
            onLoad={this.handleLoad}
            title={object.attributes.name}
            nestedScrollEnabled
          />
          {/*
          <View style={styles.loaderWrapper} pointerEvents="none">
            <LoaderBar visible={loading} />
          </View>
          */}
        </View>
      </ActionWrapper>
    )
  }
}

const mapStateToProps = (state, props) => {
  const uri = evaluateBinding(state, props.object.attributes.uri, props)

  return {
    uri,
  }
}

export default connect(mapStateToProps)(WebViewWrapper)

const styles = StyleSheet.create({
  webView: {
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0)',
  },
  innerWrapper: {
    flex: 1,
  },
  loaderWrapper: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    height: 2,
    alignItems: 'center',
    justifyContent: 'center',
  },
})
