import React, { Component } from 'react'
import { View } from 'react-native'

export default class WebView extends Component {
  render() {
    const { source, onLoad, title } = this.props
    const { style } = this.props
    const uri = source && source.uri

    const frameStyles = {
      borderStyle: 'none',
      borderWidth: 0,
      flex: 1,
      height: '100%',
    }

    return (
      <View style={style}>
        <iframe
          title={title}
          webkitAllowFullScreen
          mozallowfullscreen
          allowFullScreen
          allow="camera *; microphone *"
          src={uri}
          onLoad={onLoad}
          style={frameStyles}
        />
      </View>
    )
  }
}
