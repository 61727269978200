import { httpMethods, defaultMethods } from '@adalo/constants'

export const getCollection = (datasource, collectionId) => {
  if (!datasource || !datasource.collections) {
    return {}
  }
  return datasource.collections && datasource.collections[collectionId]
}

export const getEndpoint = (datasource, collectionId, endpointId) => {
  const collection = getCollection(datasource, collectionId)

  const endpoints = (collection && collection.endpoints) || []

  return endpoints.filter(e => e.id === endpointId)[0]
}

export const getEndpointMethod = endpoint => {
  let method = httpMethods.GET
  method = endpoint.method || defaultMethods[endpoint.type] || method

  return method
}
