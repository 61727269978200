import { cloneDeep } from './objects'
import { getBindingValue } from './dependencies'

export const isExternalCollection = ({ datasource, tableId }) => {
  return (
    datasource &&
    datasource.tables[tableId] &&
    datasource.tables[tableId].type === 'api'
  )
}

export const getExternalCollectionBindings = ({ datasource, tableId }) => {
  const table = datasource.tables[tableId]
  if (!table) return

  const bindingIds = []
  const bindings = []
  const { auth, endpoints } = table

  if (auth) {
    for (const authObj of auth) {
      if (Array.isArray(authObj.value)) {
        for (const entity of authObj.value) {
          if (entity.id && !bindingIds.includes(entity.id)) {
            bindingIds.push(entity.id)
            bindings.push(entity)
          }
        }
      }
    }
  }

  const endpointKeys = Object.keys(endpoints)

  for (const endpointKey of endpointKeys) {
    const endpoint = endpoints[endpointKey]
    if (Array.isArray(endpoint.url)) {
      for (const entity of endpoint.url) {
        if (entity.id && !bindingIds.includes(entity.id)) {
          bindingIds.push(entity.id)
          bindings.push(entity)
        }
      }
    }
  }

  const result = {}

  for (let i = 0; i < bindingIds.length; i += 1) {
    result[bindingIds[i]] = bindings[i]
  }

  return result
}

// Given the auth bindings, resolve them into their actual values.
export const resolveAuth = ({ datasource, tableId, state, getParams }) => {
  const table = datasource.tables[tableId]
  if (!table) return

  const { auth } = table
  if (!auth) return

  let resolvedBindings = false
  const newAuth = cloneDeep(auth)

  for (let i = 0; i < newAuth.length; i += 1) {
    const authObj = newAuth[i]
    // Auth's value is a binding. Resolve it.
    if (Array.isArray(authObj.value)) {
      authObj.value = getBindingValue(authObj.value, {
        state,
        getParams,
        binding: buildGetBinding(),
        bindingsList: buildGetBindingsList(),
      })
      resolvedBindings = true
    }
  }

  if (resolvedBindings) {
    return newAuth
  }

  return null
}

// Given the endpoints bindings, resolve them into their actual values.
export const resolveEndpoints = ({ datasource, tableId, state, getParams }) => {
  const table = datasource.tables[tableId]
  if (!table) return

  const { endpoints } = table
  let resolvedBindings = false
  const newEndpoints = cloneDeep(endpoints)
  const endpointKeys = Object.keys(newEndpoints)

  for (const endpointKey of endpointKeys) {
    const endpoint = newEndpoints[endpointKey]
    // Endpoint's url is a binding. Resolve it.
    if (Array.isArray(endpoint.url)) {
      endpoint.url = getBindingValue(endpoint.url, {
        state,
        getParams,
        binding: buildGetBinding(),
        bindingsList: buildGetBindingsList(),
        externalCollection: true,
      })
      resolvedBindings = true
    }
  }

  if (resolvedBindings) {
    return newEndpoints
  }

  return null
}

const buildGetBinding = () => {
  return () => null
}

const buildGetBindingsList = () => {
  return () => []
}
