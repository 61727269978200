import React, { Component } from 'react'

import SelectOption from './SelectOption'
import SelectArrow from './SelectArrow'

export default class Select extends Component {
  handleSelect = index => () => {
    let { options, onChange } = this.props

    if (options[index]) {
      onChange(options[index].value)
    }
  }

  renderOption = index => {
    let { options } = this.props

    return (
      <SelectOption
        key={options[index].value}
        label={options[index].label}
      />
    )
  }

  render() {
    let { options, numeric } = this.props
    let upArrowEnabled = options[1] && !options[1].disabled
    let downArrowEnabled = options[3] && !options[3].disabled

    return (
      <div
        className={[
          'date-picker-select',
          numeric ? 'date-picker-select-numeric' : ''
        ].join(' ')}
      >
        <SelectArrow
          direction="up"
          onClick={this.handleSelect(1)}
          enabled={upArrowEnabled}
        />
        <div className="date-picker-select-options">
          {options.map((opt, i) => this.renderOption(i))}
        </div>
        <SelectArrow
          direction="down"
          onClick={this.handleSelect(3)}
          enabled={downArrowEnabled}
        />
      </div>
    )
  }
}
