import { TLocalizedMessages } from './types'

export const closeMessages: TLocalizedMessages = {
  en: {
    body: 'Close',
  },
  es: {
    body: 'Cerrar',
  },
  fr: {
    body: 'Fermer',
  },
  ja: {
    body: '閉じる',
  },
  zh: {
    body: '关闭',
  },
  ru: {
    body: 'Закрыть',
  },
  de: {
    body: 'Schließen',
  },
  pt: {
    body: 'Fechar',
  },
  hi: {
    body: 'बंद करें',
  },
  id: {
    body: 'Menutup',
  },
  he: {
    body: 'סגור',
  },
  ko: {
    body: '닫힌',
  },
  it: {
    body: 'chiudere',
  },
  ar: {
    body: 'لإغلاق',
  },
}

export const cancelMessages: TLocalizedMessages = {
  en: {
    body: 'Cancel',
  },
  es: {
    body: 'Cancelar',
  },
  fr: {
    body: 'Annuler',
  },
  ja: {
    body: 'キャンセル',
  },
  zh: {
    body: '取消',
  },
  ru: {
    body: 'Отменить',
  },
  de: {
    body: 'Abbrechen',
  },
  pt: {
    body: 'Cancelar',
  },
  hi: {
    body: 'रद्द करें',
  },
  id: {
    body: 'Membatalkan',
  },
  he: {
    body: 'לבטל',
  },
  ko: {
    body: '취소',
  },
  it: {
    body: 'annullare',
  },
  ar: {
    body: 'إلغاء',
  },
}
