// NOTE: Only works for single-level objects with string-serializeable values
export const hashObject = obj => {
  const keys = Object.keys(obj)
  keys.sort()

  const result = keys
    .map(k => {
      return [
        encodeURIComponent(k),
        '=',
        encodeURIComponent(String(obj[k])),
      ].join('')
    })
    .join(',')

  return result
}

export const shallowEqual = (obj1, obj2) => {
  return hashObject(obj1) === hashObject(obj2)
}
