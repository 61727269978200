import React, { Component } from 'react'

export default class SelectOption extends Component {
  render() {
    let { label } = this.props

    return (
      <div className="date-picker-select-option">
        <div className="date-picker-select-option-value">
          {label}
        </div>
      </div>
    )
  }
}
