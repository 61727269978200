import { RangeWithY, Range } from './types'

export const getXRangeWithY = (
  x: number,
  width: number,
  y: number
): RangeWithY => ({ start: x, end: x + width, y })

export const inRange = (num: number, { start, end }: Range) =>
  start <= num && num <= end

export const containsRange = (
  containingRange: Range,
  containedRange: Range
): boolean =>
  containingRange.start >= containedRange.start &&
  containingRange.end <= containedRange.end

export const overlapsRange = ({ start, end }: Range, range: Range) =>
  inRange(start, range) ||
  inRange(end, range) ||
  containsRange(range, { start, end })

export const overlapsRangeWithDifferentY = (
  range: RangeWithY,
  range2: RangeWithY
) => overlapsRange(range, range2) && range.y !== range2.y
