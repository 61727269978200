import axios from 'axios'
import { unsafeGetToken } from '../ducks/auth'

// eslint-disable-next-line @typescript-eslint/default-param-last
export const request = (datasourceId, url, method = 'get', data) => {
  const config = {
    method,
    url,
    data,
  }

  const token = unsafeGetToken(datasourceId)

  if (token) {
    config.headers = {
      Authorization: `Bearer ${token}`,
    }
  }

  return axios(config)
}
