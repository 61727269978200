import axios from 'axios'
import { unsafeGetToken } from '../ducks/auth'

const getToken = app => {
  return unsafeGetToken(null, app)
}

export const getUserValue = async (app, baseURL, library, key) => {
  try {
    const token = getToken(app)

    if (!token) {
      return null
    }

    const result = await axios.get(`${baseURL}/values`, {
      headers: { Authorization: `Bearer ${token}` },
    })

    const { data } = result

    return data[library] && data[library][key]
  } catch (err) {
    return null
  }
}

export const setUserValue = async (app, baseURL, library, key, value) => {
  const url = `${baseURL}/values/${library}/${key}`
  const payload = { value }
  const token = getToken(app)

  if (!token) {
    return { [library]: {} }
  }

  const result = await axios.put(url, payload, {
    headers: { Authorization: `Bearer ${token}` },
  })

  return result.data
}
