import { actionTypes } from '@adalo/constants'

const DATA_ACTION_TYPES = new Set([
  actionTypes.CREATE_OBJECT,
  actionTypes.UPDATE_OBJECT,
  actionTypes.DELETE_OBJECT,
  actionTypes.CREATE_ASSOCIATION,
  actionTypes.DELETE_ASSOCIATION,
  actionTypes.AUTHENTICATE,
  actionTypes.LOGOUT,
  actionTypes.SIGNUP,
  actionTypes.SET_INPUT_VALUE,
  actionTypes.CUSTOM,
])

export const isDataAction = action => DATA_ACTION_TYPES.has(action.actionType)

export const hasDataAction = actionChain => {
  for (const action of actionChain.actions) {
    if (isDataAction(action)) {
      return true
    }
  }

  return false
}
