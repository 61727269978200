import { getItemById } from '../ducks/data'

export const addRelatedBindings = (bindings, state, app) => {
  const newBindings = {}

  for (const key of Object.keys(bindings)) {
    const { datasources } = app
    const [datasourceId, tableId] = key.split('.')

    if (datasources[datasourceId].tables) {
      const table = datasources[datasourceId].tables[tableId]

      const referenceFields = Object.keys(table.fields).filter(
        fieldId => table.fields[fieldId].type.type === 'belongsTo'
      )

      const record = getItemById(state, tableId, bindings[key])

      referenceFields.forEach(fieldId => {
        const { tableId } = table.fields[fieldId].type
        const key = `${datasourceId}.${tableId}`

        if (!newBindings[key]) {
          newBindings[key] = record[fieldId]
        }
      })
    }
  }

  return { ...newBindings, ...bindings }
}

/**
 * Determine if a binding contains a random formula
 * @param {object | object[]} binding
 * @returns {boolean} boolean
 */
export const doesBindingContainRandomFormula = binding => {
  if (!binding) return false

  if (Array.isArray(binding)) {
    return binding.some(b => doesBindingContainRandomFormula(b))
  }

  if (!binding.parsedFormula || !Array.isArray(binding.parsedFormula)) {
    return false
  }

  return binding.parsedFormula.includes('random')
}
