import AsyncStorage from '@react-native-async-storage/async-storage'
import axios from 'axios'

import { loginUrl, loginUrlExternal } from '../utils/urls'
import { getPayload } from '../utils/jwt'

const LOAD_SUCCESS = Symbol('LOAD_SUCCESS')
const LOAD_COMPLETE = Symbol('LOAD_COMPLETE')
export const LOGOUT = Symbol('LOGOUT')
const AUTHENTICATE = 'AUTHENTICATE'
const SET_AUTH_TOKEN = Symbol('Set_AUTH_TOKEN')
const SET_TEMPORARY_LOGIN_STATUS = Symbol('SET_TEMPORARY_LOGIN_STATUS')

const tokens = {}

// Unsafe because it's outside redux
// Needed for auth in certain cases
export const unsafeGetToken = (datasourceId, app = null) => {
  if (app && !datasourceId) {
    // eslint-disable-next-line prefer-destructuring
    datasourceId = Object.keys(app.datasources)[0]
  }

  return tokens[datasourceId]
}

const tokenKey = datasourceId => {
  if (!datasourceId) {
    throw new Error('datasourceId cannot be blank')
  }

  return `SESSION_TOKEN-${datasourceId}`
}

const INITIAL_STATE = {
  tokens: {},
  activeAuth: null,
  restartComplete: false,
  userUsedTemporaryLogin: false,
}

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = INITIAL_STATE, action) => {
  if (action.type === LOAD_SUCCESS) {
    const { datasourceId, token } = action

    tokens[datasourceId] = token

    return {
      ...state,
      activeAuth: null,
      tokens: {
        ...state.tokens,
        [datasourceId]: token,
      },
    }
  }

  if (action.type === LOAD_COMPLETE) {
    return {
      ...state,
      restartComplete: true,
    }
  }

  if (action.type === `${AUTHENTICATE}_FULFILLED`) {
    const {
      payload: { datasourceId, response },
    } = action

    AsyncStorage.setItem(tokenKey(datasourceId), response.data.token)

    // For unsafeGetToken
    tokens[datasourceId] = response.data.token

    let userUsedTemporaryLogin = false
    if (response.data.temporary) userUsedTemporaryLogin = true

    return {
      ...state,
      tokens: {
        ...state.tokens,
        [datasourceId]: response.data.token,
      },
      userUsedTemporaryLogin,
    }
  }

  if (action.type === `${AUTHENTICATE}_REJECTED`) {
    console.log('----------------> LOGIN FAILED .....................')
  }

  if (action.type === `${AUTHENTICATE}_PENDING`) {
    console.log('------------------> REQUEST STARTED..........')
  }

  if (action.type === LOGOUT) {
    const { datasourceIds } = action

    datasourceIds.forEach(id => {
      delete tokens[id]

      AsyncStorage.removeItem(tokenKey(id))
        .then(() => {})
        .catch(() => {})
    })

    return {
      ...INITIAL_STATE,
      restartComplete: true,
    }
  }

  if (action.type === SET_AUTH_TOKEN) {
    const { datasourceId, token } = action

    AsyncStorage.setItem(tokenKey(datasourceId), token)

    // For unsafeGetToken
    tokens[datasourceId] = token

    return {
      ...state,
      tokens: {
        ...state.tokens,
        [datasourceId]: token,
      },
    }
  }

  if (action.type === SET_TEMPORARY_LOGIN_STATUS) {
    let { value } = action
    value = !!value
    return {
      ...state,
      userUsedTemporaryLogin: value,
    }
  }

  return { ...state }
}

// ACTIONS

export const restartSession = () => dispatch => {
  ;(async () => {
    const keys = await AsyncStorage.getAllKeys()

    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i]
      const match = key.match(/^SESSION_TOKEN-(\w+)$/)

      if (match) {
        const datasourceId = match[1]
        // eslint-disable-next-line no-await-in-loop
        const token = await AsyncStorage.getItem(key)

        if (token) {
          dispatch({ type: LOAD_SUCCESS, datasourceId, token })
        }
      }
    }

    dispatch({ type: LOAD_COMPLETE })
  })()
}

export const authenticate = (baseURL, datasourceId, data) => ({
  type: AUTHENTICATE,
  payload: async () => ({
    datasourceId,
    response: await axios.post(loginUrl(baseURL, datasourceId), data),
  }),
})

export const authenticateOAuth = (datasourceId, response) => ({
  type: AUTHENTICATE,
  payload: async () => ({
    datasourceId,
    response,
  }),
})

export const authenticateExternal = (baseURL, datasourceId, config) => ({
  type: AUTHENTICATE,
  payload: async () => ({
    datasourceId,
    response: await axios.post(loginUrlExternal(baseURL, datasourceId), config),
  }),
})

export const setAuthToken = (datasourceId, token) => ({
  type: SET_AUTH_TOKEN,
  datasourceId,
  token,
})

export const logout = datasourceIds => ({
  type: LOGOUT,
  datasourceIds,
})

export const setTemporaryLoginStatus = value => ({
  type: SET_TEMPORARY_LOGIN_STATUS,
  value,
})

// SELECTORS

export const getAuthToken = (state, datasourceId) => {
  return state.auth.tokens[datasourceId]
}

export const getAuthenticated = (state, datasources) => {
  if (!datasources) return null

  const { tokens, restartComplete } = state.auth
  const datasourceIds = Object.keys(datasources)

  if (!restartComplete) return null

  for (const datasourceId of datasourceIds) {
    if (!datasourceId) continue

    const payload = getPayload(tokens[datasourceId])

    if (payload) return true
  }

  return false
}
