import axios from 'axios'

export const executeAPICustomAction = async (
  dispatch,
  options,
  dependencies,
  helpers
) => {
  const { customActionId } = options

  const { getBaseAPIURL } = helpers

  const inputs = {}

  for (const itm in dependencies) {
    inputs[itm] = dependencies[itm].value
  }

  const app = helpers.getApp()
  const { Organization, id } = app

  inputs.organizationId = Organization.id
  inputs.appId = id

  const url = `${getBaseAPIURL()}/custom-action/${customActionId}/execute`
  return axios.post(url, inputs)
}
