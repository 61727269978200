import React, { Component } from 'react'

export default class SelectArrow extends Component {
  getArrowIcon() {
    let { direction } = this.props

    switch(direction) {
      case 'up':
        return '▲'
      case 'down':
        return '▼'
      default:
        return ''
    }
  }

  render() {
    let { enabled, onClick } = this.props

    return (
      <div
        className={[
          'date-picker-select-arrow',
          enabled ? 'date-picker-select-arrow-enabled' : ''
        ].join(' ')}
        onClick={onClick}
      >
        <span>{this.getArrowIcon()}</span>
      </div>
    )
  }
}
