import { TLocalizedMessages } from './types'

export const fileTooLargeMessages: TLocalizedMessages = {
  en: {
    title: 'File Too Large',
    body: 'Files must be less than 50MB in size',
  },
  es: {
    title: 'El archivo es demasiado grande',
    body: 'Los archivos deben tener un tamaño inferior a 50MB',
  },
  fr: {
    title: 'Le fichier est trop grand',
    body: 'Les fichiers doivent avoir une taille inférieure à 50 mégaoctets',
  },
  ja: {
    title: 'ファイルのサイズが大きすぎる',
    body: 'ファイルのサイズが50メガバイト以下であること',
  },
  zh: {
    title: '文件太大',
    body: '文件大小必须低于50兆字节',
  },
  ru: {
    title: 'Файл слишком большой',
    body: 'Размер файлов не должен превышать 50 мегабайт',
  },
  de: {
    title: 'Die Datei ist zu groß',
    body: 'Dateien müssen unter 50 Megabyte groß sein',
  },
  pt: {
    title: 'O arquivo é grande demais',
    body: 'Os arquivos devem ter menos de 50 megabytes de tamanho',
  },
  hi: {
    title: 'फ़ाइल बहुत बड़ी है',
    body: 'फ़ाइलें आकार में पचास मेगाबाइट से कम होनी चाहिए',
  },
  id: {
    title: 'File terlalu besar',
    body: 'File harus berukuran kurang dari lima puluh megabita',
  },
  he: {
    title: 'הקובץ גדול מדי',
    body: 'קבצים חייבים להיות פחות מחמישים מגה',
  },
  ko: {
    title: '파일이 너무 큽니다',
    body: '파일은 50MB 미만이어야합니다.',
  },
  it: {
    title: 'il file è troppo grande',
    body: 'Il file deve essere inferiore a cinquanta megabyte',
  },
  ar: {
    title: 'الملف كبير جدًا',
    body: 'يجب أن يكون الملف أقل من خمسين ميغا بايت.',
  },
}
