import React from 'react'
import { ActivityIndicator, View, StyleSheet } from 'react-native'
import { bindingTypes } from '@adalo/constants'

import { evaluateBinding } from '../../utils/dependencies'
import { getFieldValue } from '../../utils/sources'

import ActionWrapper from '../ActionWrapper'
import { Input, connectInput } from '../Input'
import SelectSub from './SelectSub'

export const defaults = {
  COLOR: '#9e9e9e',
  BACKGROUND_COLOR: '#fff',
}

class Select extends Input {
  state = {
    selectStyles: {},
    options: [],
    isLoading: false,
  }

  static className = 'Select'

  componentDidMount() {
    this.handleLoading()
    this.handleStyles()
    this.handleOptions()

    const { setDefaultValue, value, defaultValue } = this.props
    const inputId = this.getInputId()

    setDefaultValue(inputId, defaultValue || value)
  }

  componentDidUpdate(prevProps) {
    const {
      options,
      object,
      bindingData,
      topScreen,
      defaultValue,
      setDefaultValue,
    } = this.props
    if (topScreen) {
      if (prevProps.object.attributes !== object.attributes) {
        this.handleStyles()
      }

      if (prevProps.object !== object || prevProps.options !== options) {
        this.handleOptions()
      }

      if (
        prevProps.object.dataBinding !== object.dataBinding ||
        prevProps.bindingData !== bindingData
      ) {
        this.handleLoading()
      }
    }

    if (prevProps.defaultValue !== defaultValue) {
      const id = this.getInputId()
      setDefaultValue(id, defaultValue)
    }
  }

  handleChange = value => {
    const { changeValue, inputId } = this.props

    changeValue(inputId, value)
  }

  handleLoading = () => {
    const { object, bindingData } = this.props

    if (
      object.dataBinding &&
      object.dataBinding.bindingType === bindingTypes.LIST &&
      !Array.isArray(bindingData)
    ) {
      this.setState({ isLoading: true })
    } else {
      this.setState({ isLoading: false })
    }
  }

  getOptions = () => {
    const { options, object } = this.props
    const { attributes } = object
    const { select } = attributes

    const bindings = this.getDataBindingsOfType(bindingTypes.LIST)

    if (!options || !select || !select.options || !select.options.labelField) {
      if (bindings && bindings.length > 0) {
        const { binding, data } = bindings[0]

        if (data) {
          const { labelField, valueField } = binding.options || {}

          const fieldKey = valueField || 'id'

          const options = data.map(itm => ({
            label: String(getFieldValue(itm, labelField)),
            value: itm[fieldKey],
          }))

          return options
        }
      }

      return []
    }

    const { labelField } = select.options

    return options.map(opt => ({
      value: opt.id,
      label: String(getFieldValue(opt, labelField)),
    }))
  }

  handleOptions = () => {
    const { object } = this.props
    const { attributes } = object
    const { placeholder } = attributes

    const options = this.getOptions()

    if (placeholder) {
      return this.setState({
        options: [
          {
            value: '_placeholder',
            label: placeholder,
          },
          ...options,
        ],
      })
    }

    return this.setState({ options })
  }

  getSelectStyles = () => {
    const { object } = this.props
    const { opacity } = object.attributes

    const borderStyles = this.borderStyles()
    const backgroundStyles = this.backgroundStyles()
    const shadowStyles = this.shadowStyles()
    const fontStyles = this.textStyles()

    return {
      ...borderStyles,
      ...backgroundStyles,
      ...shadowStyles,
      ...fontStyles,
      opacity,
    }
  }

  handleStyles = () => {
    const selectStyles = this.getSelectStyles()

    this.setState({ selectStyles })
  }

  render() {
    const { selectStyles, options, isLoading } = this.state
    const { app, object, component, bindingData, defaultValue } = this.props
    let { value } = this.props
    const { layout } = object

    if (value === undefined) {
      value = defaultValue || '_placeholder'
    }

    return (
      <View style={layout}>
        <ActionWrapper
          bindingData={bindingData}
          component={component}
          object={object}
          style={{ opacity: isLoading ? 0.5 : 1 }}
        >
          <SelectSub
            object={object}
            style={selectStyles}
            options={options}
            value={value}
            onChange={this.handleChange}
            branding={app.branding}
          />
        </ActionWrapper>
        {isLoading ? (
          <View style={styles.loaderWrapper}>
            <ActivityIndicator color="#999999" />
          </View>
        ) : null}
      </View>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { object, getBinding, getBindingsList, getParams, getApp } = ownProps
  const { attributes } = object

  const binding = object.attributes.select

  if (!binding) {
    return {}
  }

  // select default value
  let defaultValue

  // check if a default value has been set
  if (attributes.defaultValue) {
    // grab the binding data for default value
    const value = evaluateBinding(state, attributes.defaultValue, {
      getParams,
      getBinding,
      getBindingsList,
      getApp,
    })

    // set default value to the id
    defaultValue = value ? value.id : null
  }

  return {
    options: evaluateBinding(state, binding, {
      getParams,
      getBinding,
      getBindingsList,
      getApp,
    }),
    defaultValue,
  }
}

export default connectInput(Select, null, mapStateToProps)

const styles = StyleSheet.create({
  loaderWrapper: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
})
