export const findFormInGroups = (groups, objectId) => {
  if (!groups || !objectId) return null
  let form = null

  for (const group of groups) {
    const { children } = group
    form = children.find(
      ({ id, type }) => objectId.includes(id) && type === 'form'
    )

    if (form) {
      return form
    } else {
      const nestedGroups = children.filter(({ type }) => type === 'group')
      form = findFormInGroups(nestedGroups, objectId)
      if (form) {
        return form
      }
    }
  }
  return form
}
