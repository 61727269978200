import React, { Component } from 'react'
import { createPortal } from 'react-dom'

let _node = null

const getNode = () => {
  if (!_node && !(_node = document.querySelector('date-picker-portal'))) {
    _node = document.createElement('div')
    _node.className = 'date-picker-modal-portal'
    document.body.appendChild(_node)
  }

  return _node
}

export default class ModalWrapper extends Component {
  render() {
    let { children } = this.props

    return createPortal(
      children,
      getNode()
    )
  }
}
