import { dataTypes } from '@adalo/constants'

export const share = async (source, bindingData, helpers) => {
  if (!source) {
    return
  }

  if (window.navigator.share) {
    const shareExtensions = [dataTypes.IMAGE, dataTypes.FILE]

    if (
      !source ||
      (!shareExtensions.includes(source.dataType) && typeof source !== 'string')
    ) {
      // eslint-disable-next-line no-alert
      window.alert('Object Type not supported for sharing.')
    }

    const shareOpts = { title: 'Share' }

    if (typeof source === 'string' || source.dataType === dataTypes.TEXT) {
      shareOpts.text = bindingData
    } else {
      const baseURL =
        source.dataType === dataTypes.IMAGE
          ? helpers.getImageUploadsBaseURL()
          : helpers.getFileUploadsBaseURL()

      shareOpts.url = `${baseURL}/${bindingData.url}`
    }

    await navigator
      .share(shareOpts)
      .then()
      .catch(err => {
        console.log(`Couldn't share because of`, err.message)
      })
  } else {
    // eslint-disable-next-line no-alert
    window.alert('Sharing is not supported by this browser.')
  }
}
