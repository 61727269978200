import React from 'react'
import PropTypes from 'prop-types'

import {
  View,
  Text,
  ActivityIndicator,
  TouchableOpacity,
  StyleSheet,
} from 'react-native'

import { FilePicker } from 'utils/uploads'
import {
  chooseFileMessages,
  removeMessages,
  errorUploadingFileMessages,
  getLocalizedText,
} from 'utils/languageLocale'
import { uploadFile } from 'ducks/formInputs'
import { Alert } from '../utils/alerts'

import { connectInput } from './Input'
import BaseObject from './BaseObject'

class FileUpload extends BaseObject {
  static contextTypes = {
    getBaseURL: PropTypes.func,
  }

  state = {
    previewFilename: null,
  }

  static className = 'FileUpload'

  handleClear = () => {
    const { object, resetValue } = this.props

    resetValue(object.id)
  }

  componentWillUnmount() {
    const { object, resetValue } = this.props

    if (resetValue && object) {
      resetValue(object.id)
    }
  }

  handlePress = () => {
    FilePicker.showFilePicker(this.processFile)
  }

  processFile = async (filename, data) => {
    if (filename && typeof filename === 'object') {
      data = filename.data
      filename = filename.filename
    }

    const { uploadFile, object } = this.props

    this.setState({ previewFilename: filename })

    const baseURL = this.context.getBaseURL()

    try {
      await uploadFile(baseURL, object.id, filename, data)
    } catch (err) {
      console.error('ERROR UPLOADING FILE:', err)
      const { title, body } = getLocalizedText(errorUploadingFileMessages)
      Alert.alert(title, body)
    }

    this.setState({ previewFilename: null })
  }

  render() {
    const { object, value } = this.props
    const { previewFilename } = this.state
    const { attributes, layout } = object

    const { height, color } = attributes
    const wrapperStyles = {
      ...layout,
      ...this.borderStyles(),
      ...this.getOpacity(),
      height,
    }

    const loading = !!previewFilename
    const filename = (value && value.filename) || previewFilename

    const filenameStyles = [styles.filename]

    const textStyles = {
      ...this.textStyles(),
      color: color || '#06f',
    }

    if (loading) {
      filenameStyles.push({ opacity: 0.5 })
    }

    return (
      <View style={[styles.wrapper, wrapperStyles]}>
        {filename ? (
          <View style={styles.fileWrapper}>
            <View style={filenameStyles}>
              <Text style={styles.filenameText}>{filename}</Text>
            </View>
            {loading ? (
              <View style={styles.loaderWrapper}>
                <ActivityIndicator color="#999999" />
              </View>
            ) : (
              <TouchableOpacity
                onPress={this.handleClear}
                style={styles.clearButton}
              >
                <Text style={styles.clearText}>
                  {getLocalizedText(removeMessages).body}
                </Text>
              </TouchableOpacity>
            )}
          </View>
        ) : (
          <TouchableOpacity onPress={this.handlePress} style={styles.trigger}>
            <View style={styles.innerView}>
              <Text style={[styles.text, textStyles]}>
                {getLocalizedText(chooseFileMessages).body}
              </Text>
            </View>
          </TouchableOpacity>
        )}
      </View>
    )
  }
}

const styles = StyleSheet.create({
  wrapper: {},
  trigger: {
    flex: 1,
  },
  innerView: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    fontSize: 14,
    fontWeight: '500',
    textAlign: 'center',
  },
  fileWrapper: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  filename: {},
  filenameText: {
    textAlign: 'center',
  },
  clearButton: {
    marginTop: 10,
  },
  clearText: {
    color: '#8a8a8a',
    fontWeight: '500',
    fontSize: 12,
  },
  loaderWrapper: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
})

export default connectInput(FileUpload, { uploadFile })
