import moment from 'moment'

export const dateFriendly = dateString => {
  let currentDate = moment()
  let dateObj = moment(dateString)

  if (dateObj.isSame(currentDate, 'day')) {
    return 'Today'
  } else if (moment(dateObj).subtract(1, 'day').isSame(currentDate, 'day')) {
    return 'Tomorrow'
  } else if (moment(dateObj).add(1, 'day').isSame(currentDate, 'day')) {
    return 'Yesterday'
  }

  return dateObj.format('ddd MMM D')
}
