import {
  bindingTypes,
  dataTypes,
  sortDirections,
  listTypes,
  LIST,
} from '@adalo/constants'
import { kilometersBetweenLocations } from './location'
import { getBindingValue } from './dependencies'

export const sortItems = (items, dataBinding, opts) => {
  if (!items) {
    items = []
  }

  if (
    !dataBinding ||
    dataBinding.bindingType !== bindingTypes.LIST ||
    !dataBinding.source ||
    !dataBinding.source.sort ||
    !dataBinding.source.sort.fieldId
  ) {
    return items
  }

  // Now we know it's a list binding...
  const { sort } = dataBinding.source
  const direction = sort.direction || sortDirections.ASC
  const { fieldId, reference } = sort

  items = items.slice(0)

  const multiple = direction === sortDirections.ASC ? 1 : -1

  let getValue = a => a[fieldId]

  if (sort.type === dataTypes.LOCATION && reference) {
    const { source, fallback } = reference || {}
    let sortReferenceValue = getBindingValue(source, opts)
    if (!sortReferenceValue) {
      sortReferenceValue = fallback
    }
    if (sortReferenceValue) {
      getValue = a => {
        if (a[fieldId] && a[fieldId].coordinates) {
          return kilometersBetweenLocations(
            a[fieldId].coordinates,
            sortReferenceValue.coordinates
          )
        }
        return -1
      }
    }
  }

  items.sort((item1, item2) => {
    let a = getValue(item1)
    let b = getValue(item2)

    if (typeof a === 'string' && typeof b === 'string') {
      a = a.toLowerCase()
      b = b.toLowerCase()
    }

    return a > b ? multiple : -multiple
  })

  return items
}

export const isListObject = obj => {
  return obj.type === LIST && obj.attributes.listType !== listTypes.GRID
}

export const listsEqual = (list1, list2) => {
  if (!list1 || !list2 || list1.length !== list2.length) {
    return false
  }

  for (let i = 0; i < list1.length; i += 1) {
    if (list1[i] !== list2[i]) {
      return false
    }
  }

  return true
}
