import { TLocalizedMessages } from './types'

export const missingFileMessages: TLocalizedMessages = {
  en: {
    title: 'File does not exist',
    body: 'The file you tried to pick does not exist',
  },
  es: {
    title: 'El archivo no existe',
    body: 'El archivo que intentaste elegir no existe',
  },
  fr: {
    title: 'Fichier ne existe pas',
    body: "Le fichier que vous avez essayé de sélectionner n'existe pas",
  },
  ja: {
    title: 'ファイルが存在しません',
    body: '選択しようとしたファイルが存在しません',
  },
  zh: {
    title: '文件不存在',
    body: '您尝试选择的文件不存在',
  },
  ru: {
    title: 'Файл, который вы пытались выбрать, не существует',
    body: 'Файл не существует',
  },
  de: {
    title: 'Die Datei existiert nicht',
    body: 'Die Datei, die Sie auswählen möchten, existiert nicht',
  },
  pt: {
    title: 'Arquivo não existe',
    body: 'O arquivo que você tentou escolher não existe',
  },
  hi: {
    title: 'फ़ाइल मौजूद नहीं है',
    body: 'आपने जिस फ़ाइल को चुनने का प्रयास किया वह मौजूद नहीं है',
  },
  id: {
    title: 'File tidak ada',
    body: 'File yang Anda coba pilih tidak ada',
  },
  he: {
    title: 'הקובץ אינו קיים',
    body: 'הקובץ שניסית לבחור אינו קיים',
  },
  ko: {
    title: '파일이 없습니다',
    body: '선택하려는 파일이 존재하지 않습니다',
  },
  it: {
    title: 'Il file non esiste',
    body: 'Il file che hai provato a selezionare non esiste',
  },
  ar: {
    title: 'الملف غير موجود',
    body: 'الملف الذي حاولت اختياره غير موجود',
  },
}
