import Section, { SectionProps } from 'components/Section'

interface EllipseProps extends SectionProps {}

export default class Ellipse<T extends EllipseProps> extends Section<T> {
  getBorderRadius() {
    const { object } = this.props
    const { attributes } = object
    const { height } = attributes

    return height * 2
  }

  additionalStyles() {
    const {
      object: { attributes, layout },
    } = this.props
    return {
      height: layout?.height || attributes.height,
    }
  }
}
