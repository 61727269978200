import axios from 'axios'
import { getUserLanguageCode } from './user-locale'

export const fetchAutocompleteResults = async (
  baseUrl,
  app,
  token,
  search,
  coordinates
) => {
  let params = { search, token, language: getUserLanguageCode() }

  if (coordinates) {
    params = {
      ...params,
      ...coordinates,
    }
  }

  try {
    const res = await axios.get(`${baseUrl}/proxy-api/google/${app.id}`, {
      params,
    })
    return res.data.map((item, index) => {
      let label = item.primaryText
      if (item.secondaryText) {
        label = label.concat(' ', item.secondaryText)
      }
      return {
        label,
        primaryText: item.primaryText,
        secondaryText: item.secondaryText,
        id: item.id,
        index,
      }
    })
  } catch (error) {
    let errorMessage = error.response.data.message
    if (!errorMessage) {
      errorMessage = error.response.data
    }
    return [{ errorMessage }]
  }
}

export const fetchPlaceDetails = async (baseUrl, appId, placeId, token) => {
  try {
    const res = await axios.get(`${baseUrl}/google`, {
      params: { placeId, appId, token, language: getUserLanguageCode() },
    })
    return res.data
  } catch {
    return null
  }
}
