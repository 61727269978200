import { sourceTypes, dataTypes } from '@adalo/constants'

const RELATION_TYPES = new Set([
  sourceTypes.BELONGS_TO,
  sourceTypes.HAS_MANY,
  sourceTypes.MANY_TO_MANY,
])

export const getIncludes = source => {
  let currentSource = source
  const include = []

  while (currentSource) {
    if (RELATION_TYPES.has(currentSource.type)) {
      const typeMatch = currentSource.type.match(/^(.*)Relation/)
      include.push(`${typeMatch[1]}~${currentSource.fieldId}`)
    }

    currentSource = currentSource.source
  }

  include.reverse()

  return include.length > 0 ? [include.join('.')] : []
}

export const isChainedRelations = source => {
  let currentSource = source

  while (currentSource.source) {
    if (!RELATION_TYPES.has(currentSource.type)) {
      return false
    }

    currentSource = currentSource.source
  }

  if (currentSource.type === sourceTypes.PARAM) {
    return true
  }

  if (currentSource.type !== sourceTypes.DATA || !currentSource.selector) {
    return false
  }

  return true
}

export const getRootSource = source => {
  let currentSource = source

  if (currentSource.type === sourceTypes.AUTOSAVE) {
    currentSource = currentSource.source
  }

  while (currentSource && currentSource.source) {
    // Bail early if we see nested list sources
    if (
      currentSource.dataType === dataTypes.LIST &&
      currentSource.source.dataType === dataTypes.LIST
    ) {
      return currentSource
    }

    currentSource = currentSource.source
  }

  return currentSource
}

export const getFlatIncludes = (map, includes) => {
  const dependents = {}

  Object.values(map).forEach(deps => {
    deps.forEach(dep => {
      dependents[dep] = true
    })
  })

  const topLevel = Object.keys(map).filter(k => !dependents[k])

  let results = []

  topLevel.forEach(id => {
    results = results.concat(
      flattenInclude(id, map, includes).map(include => [id, include])
    )
  })

  return results
}

export const flattenInclude = (id, map, includes) => {
  let result = []

  let base = []

  if (includes[id]) {
    result.push(includes[id])
    base = [includes[id]]
  }

  if (map[id]) {
    map[id].forEach(childId => {
      result = result.concat(
        flattenInclude(childId, map, includes).map(include =>
          base.concat(include).join('.')
        )
      )
    })
  }

  return result
}
