import React, { Component } from 'react'
import { View, StyleSheet } from 'react-native'

export default class ColumnSpacer extends Component {
  render() {
    const {
      object: { layout },
    } = this.props

    return <View style={[styles.wrapper, layout]} pointerEvents="box-none" />
  }
}

const styles = StyleSheet.create({
  wrapper: {
    minWidth: 1,
  },
})
