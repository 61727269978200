const EVALUATE_RANDOM_NUMBER = 'EVALUATE_RANDOM_NUMBER'
const RESET_RANDOM_NUMBERS = 'RESET_RANDOM_NUMBERS'

type TMeta = {
  id?: string
  componentId?: string
  multiple?: number
  offset?: number
}

type TRandomAction = {
  type: string
  meta: TMeta
}

type TNumbers = { id: string; componentId: string; value: number }

type TRandomState = {
  numbers: TNumbers[]
}

const INITIAL_STATE: TRandomState = {
  numbers: [],
}

// REDUCER

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = INITIAL_STATE, action: TRandomAction) => {
  if (!Array.isArray(state.numbers)) {
    return INITIAL_STATE
  }

  switch (action.type) {
    case EVALUATE_RANDOM_NUMBER: {
      const { id, componentId, multiple, offset } = action.meta

      if (typeof multiple !== 'number' || typeof offset !== 'number') {
        return state
      }

      const value = Math.round(Math.random() * multiple + offset)
      const newObj = { id, componentId, value }

      return {
        numbers: [...state.numbers, newObj],
      }
    }
    case RESET_RANDOM_NUMBERS: {
      const { componentId } = action.meta

      if (!componentId) {
        return { numbers: [] }
      }

      const resetNumbers = state.numbers.filter(
        item => item.componentId !== componentId
      )

      return { numbers: resetNumbers }
    }
    default:
      return state
  }
}

export const evaluateRandomNumber = (opts: TMeta) => {
  return {
    type: EVALUATE_RANDOM_NUMBER,
    meta: opts,
  }
}

export const resetRandomNumbers = (componentId: string) => {
  return {
    type: RESET_RANDOM_NUMBERS,
    meta: { componentId },
  }
}

// SELECTORS

export const getRandomNumber = (state: TRandomState, id: string) => {
  return state.numbers.find(item => item.id === id)?.value
}

// Utils

export const createRandomHash = (
  formulaId: string,
  objectId: string,
  index: string,
  listItemId?: string
) => {
  if (listItemId) {
    return `${objectId}/${formulaId}/${index}/${listItemId}`
  }

  return `${objectId}/${formulaId}/${index}`
}
