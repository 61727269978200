import { normalizeURL } from './urls'

export const openLink = async inputURL => {
  const url = normalizeURL(inputURL)

  if (!url) {
    return
  }

  const win = window.open(url)
  win.opener = null
}
