import React, { Component } from 'react'

import { defaults } from './index'

export default class SelectSub extends Component {
  state = {
    color: defaults.COLOR,
    backgroundColor: defaults.BACKGROUND_COLOR,
  }

  componentDidMount() {
    this.handleColor()
    this.handleBackgroundColor()
  }

  componentDidUpdate(prevProps) {
    const { object, value } = this.props

    if (prevProps.value !== value) {
      this.handleColor()
    }

    if (prevProps.object.attributes !== object.attributes) {
      this.handleColor()
      this.handleBackgroundColor()
    }
  }

  handleChange = e => {
    const { onChange } = this.props

    let { value } = e.currentTarget

    if (value === '_placeholder') {
      value = null
    }

    onChange(value)
  }

  handleColor = () => {
    const { object, value } = this.props
    const { attributes } = object
    const { color } = attributes

    if (value && value !== '_placeholder') {
      this.setState({ color })
    } else {
      this.handlePlaceholderColor()
    }
  }

  handlePlaceholderColor = () => {
    const { object } = this.props
    const { attributes } = object
    const { placeholderColor } = attributes

    if (placeholderColor) {
      this.setState({ color: placeholderColor })
    } else {
      this.setState({ color: defaults.COLOR })
    }
  }

  handleBackgroundColor = () => {
    const { object } = this.props
    const { attributes } = object

    const { backgroundColor } = attributes

    if (backgroundColor) {
      this.setState({ backgroundColor })
    } else {
      this.setState({ backgroundColor: defaults.BACKGROUND_COLOR })
    }
  }

  getBoxShadow() {
    const {
      object: { attributes },
    } = this.props
    const { shadow } = attributes

    if (!shadow || !shadow.enabled) {
      return
    }

    const { color, x, y, size } = shadow

    return `${x}px ${y}px ${size}px ${color}`
  }

  render() {
    const { object, style, options, value } = this.props
    const { color, backgroundColor } = this.state
    const { height, padding } = object.attributes

    const inputStyles = {
      ...style,
      height,
      appearance: 'none',
      WebkitAppearance: 'none',
      MozAppearance: 'none',
      paddingLeft: padding,
      paddingRight: padding + 12,
      backgroundImage:
        'url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iOHB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDggNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICA8cG9seWdvbiBzdHlsZT0ibm9uZSIgZmlsbD0iIzAwMDAwMCIgb3BhY2l0eT0iMC41IiBwb2ludHM9IjAgMCA0IDQgOCAwIj48L3BvbHlnb24+Cjwvc3ZnPgo=")',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: `center right ${padding}px`,
      backgroundColor,
      boxShadow: this.getBoxShadow(),
      color,
      cursor: 'pointer',
    }

    return (
      <select style={inputStyles} value={value} onChange={this.handleChange}>
        {options.map(opt => (
          <option key={opt.value} value={opt.value}>
            {opt.label}
          </option>
        ))}
      </select>
    )
  }
}
