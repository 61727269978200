export const createPath = (points, isClosed, transform) => {
  if (points.length === 0) {
    return ''
  }

  points = transformPath(points, transform)

  const firstPoint = points[0]
  let commands = [`M ${firstPoint.point.join(' ')}`]

  commands = commands.concat(
    points.slice(0, points.length - 1).map((pt, i) => getSegment(points, i))
  )

  if (isClosed) {
    commands.push(getSegment(points, points.length - 1))
    commands.push(' Z')
  }

  return commands.join('')
}

export const transformPath = (points, transform) => {
  if (!transform) {
    return points
  }

  return points.map(({ point, inControl, outControl }) => ({
    point: transform(point),
    inControl: inControl && transform(inControl),
    outControl: outControl && transform(outControl),
  }))
}

export const getSegment = (points, i) => {
  const startPoint = getPoint(points, i)
  const endPoint = getPoint(points, i + 1)
  const startControl = startPoint.outControl || startPoint.point
  const endControl = endPoint.inControl || endPoint.point

  const data = [startControl, endControl, endPoint.point]
  return ` C ${data.map(d => d.join(' ')).join(', ')}`
}

export const getPoint = (points, i) => {
  i = (i + points.length) % points.length

  return points[i]
}
