import { FORM } from '@adalo/constants'

export const getInputIds = deps => {
  const fields = (deps && deps.fields) || {}
  let inputIds = []

  inputIds = Object.values(fields)
    .map(val => val.inputId)
    .filter(val => val)

  return inputIds
}

export const validateNumberInputValue = value => {
  return value.match(/^[+-]?([\d+]*[,.]?\d*|\.\d+)?$/i)
}

export const searchForInput = (objects, targetInputType) => {
  let result = false

  const deepSearch = object => {
    for (const key of Object.keys(object)) {
      const value = object[key]

      if (value && value.type && value.type === targetInputType) {
        result = true
        break
      }

      if (!result && value && typeof value === 'object') {
        deepSearch(value)
      }
    }
  }

  deepSearch(objects)

  return result
}

export const searchForField = (objects, targetFieldType) => {
  let result = false

  const isForm = object => object && object.type && object.type === FORM

  const deepSearch = object => {
    for (const key of Object.keys(object)) {
      const value = object[key]

      if (isForm(value) && value.fields && Array.isArray(value.fields)) {
        const hasLocationField = value.fields.find(
          field => field.type === targetFieldType
        )

        if (hasLocationField) {
          result = true
          break
        }
      }

      if (!result && value && typeof value === 'object') {
        deepSearch(value)
      }
    }
  }

  deepSearch(objects)

  return result
}
