import React, { Component } from 'react'
import { View, Text } from 'react-native'

export default class MissingComponent extends Component {
  render() {
    const { name } = this.props

    return (
      <View style={styles.wrapper}>
        <Text style={styles.text}>Missing component: {name}</Text>
      </View>
    )
  }
}

const styles = {
  wrapper: {
    padding: 5,
    backgroundColor: '#fcc',
    borderColor: '#d00',
    borderWidth: 1,
  },
  text: {
    color: '#d00',
  },
}
