import { Base64 } from 'js-base64'
import { dataTypes, sourceTypes } from '@adalo/constants'

import selectors from './selectors'

export const generateFilter = (source, opts) => {
  let src
  const relation = []

  for (
    src = source;
    src && src.source && src.type !== sourceTypes.DATA;
    src = src.source
  ) {
    relation.push({
      targetTable: src.source.tableId,
      type: src.type,
      fieldId: src.fieldId,
    })
  }

  if (relation.length === 0) {
    return null
  }

  const rootSource = src

  let id

  if (rootSource.type === sourceTypes.PARAM) {
    id = opts.getParams()[rootSource.paramId]
  } else {
    const selectorFunc = selectors[rootSource.selector.type]
    id = selectorFunc(rootSource, opts)
  }

  return {
    relation,
    fieldId: 'id',
    value: id,
  }
}

export const encodeFilter = filterObj => {
  if (!filterObj) {
    return ''
  }

  return Base64.encodeURI(JSON.stringify(filterObj))
}

export const shouldFilterSource = source => {
  if (!source) {
    return false
  }

  return source.dataType === dataTypes.LIST && source.type !== sourceTypes.DATA
}
