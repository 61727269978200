import React from 'react'
import { View, StyleSheet } from 'react-native'

import { Svg, Path } from '../utils/svg'
import { createPath } from '../utils/paths'
import BaseObject from './BaseObject'
import ActionWrapper from './ActionWrapper'

export default class Shape extends BaseObject {
  transformPoint = pt => {
    const {
      object: { attributes },
    } = this.props
    const { borderWidth } = this.borderStyles()
    const { width, height } = attributes
    const [x, y] = pt

    return [x * width + borderWidth * 2, y * height + borderWidth * 2]
  }

  render() {
    const { bindingData, component, object } = this.props
    const { layout, attributes } = object
    let { width, height } = attributes
    const { backgroundColor } = this.backgroundStyles()
    const { borderWidth, borderColor } = this.borderStyles()

    width += 4 * borderWidth
    height += 4 * borderWidth

    const innerStyles = {
      width,
      height,
      left: -2 * borderWidth,
      top: -2 * borderWidth,
      position: 'absolute',
    }

    const wrapperStyles = {
      width: attributes.width,
      height: attributes.height,
    }

    return (
      <ActionWrapper
        bindingData={bindingData}
        component={component}
        object={object}
        style={[layout, styles.wrapper, wrapperStyles]}
      >
        <View style={innerStyles}>
          <Svg width={width} height={height}>
            <Path
              d={createPath(
                attributes.points,
                attributes.isClosed,
                this.transformPoint
              )}
              fill={backgroundColor}
              stroke={borderColor}
              strokeWidth={borderWidth}
            />
          </Svg>
        </View>
      </ActionWrapper>
    )
  }
}

const styles = StyleSheet.create({
  wrapper: {},
})
