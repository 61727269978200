import React from 'react'
import { View, StyleSheet } from 'react-native'

import BaseObject from './BaseObject'

export default class Line extends BaseObject {
  additionalStyles() {}

  renderBackground() {
    return null
  }

  render() {
    const { object, children } = this.props
    const { attributes, layout } = object

    const {
      paddingLeft,
      paddingRight,
      paddingTop,
      paddingBottom,
      ...layoutStyles
    } = layout

    let wrapperStyles = {
      ...layoutStyles,
      ...this.additionalStyles(),
      opacity: attributes.opacity,
    }

    const innerWrapperStyles = {
      paddingLeft,
      paddingRight,
      paddingTop,
      paddingBottom,
    }

    const borderOffset = this.getBorderOffset()
    const borderStyles = this.borderStyles()
    const borderWidth = borderStyles.borderWidth || 0

    const borderPadding = 0.5 * borderWidth + borderOffset
    borderStyles.borderTopWidth = borderWidth

    const backgroundStyles = {
      ...borderStyles,
      position: 'absolute',
      left: -borderPadding,
      top: -borderPadding,
      right: -borderPadding,
      bottom: -borderPadding,
    }

    const backgroundOverlayStyles = {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      overflow: 'hidden',
    }

    const backgroundOverlayInnerStyles = {
      flex: 1,
      margin: -borderPadding,
    }

    if (layout.height !== undefined) {
      wrapperStyles = {
        ...wrapperStyles,
        height: layout.height,
      }
    }

    return (
      <View style={[wrapperStyles]}>
        <View style={backgroundStyles}>
          <View style={backgroundOverlayStyles}>
            <View style={backgroundOverlayInnerStyles}>
              {this.renderBackground()}
            </View>
          </View>
        </View>
        <View
          style={[styles.inner, innerWrapperStyles, { height: layout.height }]}
        >
          {children}
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({})
