// Traverses objects or arrays and arbitrary combinations of these
// Different from proton-utils' traverse() which only explores object.children
export const traverse = (object, func) => {
  func(object)

  if (!object) {
    return
  }

  if (Array.isArray(object)) {
    for (let i = 0; i < object.length; i += 1) {
      traverse(object[i], func)
    }
  } else if (typeof object === 'object') {
    for (const key in object) {
      traverse(object[key], func)
    }
  }
}
