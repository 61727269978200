import { selectors } from '@adalo/constants'

import { getPayload } from './jwt'

// Selectors take SOURCE, OPTS = { authToken, getParams, getBinding, ... }

export const currentUserSelector = (source, { authToken }) => {
  const payload = getPayload(authToken)

  if (!payload) {
    return null
  }

  return payload && payload.userId
}

export const listItemSelector = (source, { getBinding }) => {
  const { listObjectId } = source.selector
  const object = getBinding(listObjectId)
  const objectId = object && object.id

  return objectId
}

export const routeParamSelector = (source, { getParams }) => {
  const { datasourceId, tableId, collectionId } = source
  const key = `${datasourceId}.${tableId || collectionId}`
  const params = getParams()

  const objectId = params[key]

  return objectId
}

export const selectValueSelector = (source, opts) => {
  const { getInputValue, getBindingsList } = opts

  if (!getInputValue || !source.selector) {
    return null
  }

  let { selectObjectId } = source.selector

  if (Array.isArray(selectObjectId)) {
    const bindingsList = getBindingsList()
    const bindingsListId = bindingsList.slice(0, selectObjectId.length - 1)

    selectObjectId = bindingsListId
      .concat([selectObjectId[selectObjectId.length - 1]])
      .join('.')
  }

  return getInputValue(selectObjectId)
}

export const createdObjectSelector = (source, opts) => {
  const { datasourceId, tableId } = source
  const createdObjects = opts.createdObjects || {}

  const getter = opts.getCreatedObject
    ? opts.getCreatedObject
    : () => createdObjects[`${datasourceId}.${tableId}`]

  return getter(datasourceId, tableId)
}

export default {
  [selectors.CURRENT_USER]: currentUserSelector,
  [selectors.LIST_ITEM]: listItemSelector,
  [selectors.ROUTE_PARAM]: routeParamSelector,
  [selectors.SELECT_VALUE]: selectValueSelector,
  [selectors.CREATED_OBJECT]: createdObjectSelector,
}

export const shouldSkipFetch = type => {
  return type === selectors.LIST_ITEM || type === selectors.CREATED_OBJECT
}
