import { TLocalizedMessages } from './types'

export const chooseFileMessages: TLocalizedMessages = {
  en: {
    body: 'Choose File',
  },
  es: {
    body: 'Elija el archivo',
  },
  fr: {
    body: 'Choisir le fichier',
  },
  ja: {
    body: 'ファイルを選ぶ',
  },
  zh: {
    body: '选择文件',
  },
  ru: {
    body: 'Выберите файл',
  },
  de: {
    body: 'Datei wählen',
  },
  pt: {
    body: 'Escolher arquivo',
  },
  hi: {
    body: 'फाइलें चुनें',
  },
  id: {
    body: 'Pilih File',
  },
  he: {
    body: 'בחר קובץ',
  },
  ko: {
    body: '파일을 선택',
  },
  it: {
    body: 'Scegli il file',
  },
  ar: {
    body: 'اختر ملف',
  },
}

export const chooseFileTypeMessages: TLocalizedMessages = {
  en: {
    body: 'Choose File Type',
  },
  es: {
    body: 'Elija el tipo de archivo',
  },
  fr: {
    body: 'Choisir le type de fichier',
  },
  ja: {
    body: 'ファイルタイプを選ぶ',
  },
  zh: {
    body: '选择文件类型',
  },
  ru: {
    body: 'Выберите тип файла',
  },
  de: {
    body: 'Dateityp wählen',
  },
  pt: {
    body: 'Escolher tipo de arquivo',
  },
  hi: {
    body: 'फाइल के प्रकार चुनें',
  },
  id: {
    body: 'Pilih Tipe File',
  },
  he: {
    body: 'בחר סוג קובץ',
  },
  ko: {
    body: '파일 유형 선택',
  },
  it: {
    body: 'Scegli il tipo di file',
  },
  ar: {
    body: 'اختر نوع الملف',
  },
}

export const fileMessages: TLocalizedMessages = {
  en: {
    body: 'File',
  },
  es: {
    body: 'Archivo',
  },
  fr: {
    body: 'Fichier',
  },
  ja: {
    body: 'ファイル',
  },
  zh: {
    body: '文件',
  },
  ru: {
    body: 'Файл',
  },
  de: {
    body: 'Datei',
  },
  pt: {
    body: 'Arquivo',
  },
  hi: {
    body: 'फाइल',
  },
  id: {
    body: 'File',
  },
  he: {
    body: 'קובץ',
  },
  ko: {
    body: '파일',
  },
  it: {
    body: 'File',
  },
  ar: {
    body: 'ملف',
  },
}

export const errorUploadingFileMessages: TLocalizedMessages = {
  en: {
    title: 'Error',
    body: 'There was an error uploading your file. Please make sure the file type is allowed and check that the file type matches the file extension.',
  },
  es: {
    title: 'Error',
    body: 'Hubo un error al subir el archivo. Por favor asegúrese de que el tipo de archivo está permitido y que el tipo de archivo coincide con la extensión del archivo.',
  },
  fr: {
    title: 'Erreur',
    body: "Il y a eu une erreur lors du téléchargement de votre fichier. Veuillez vérifier que le type de fichier est autorisé et que le type de fichier correspond à l'extension du fichier.",
  },
  ja: {
    title: 'エラー',
    body: 'ファイルのアップロード中にエラーが発生しました。ファイルタイプが許可されていることを確認し、ファイルタイプがファイルの拡張子と一致することを確認してください。',
  },
  zh: {
    title: '错误',
    body: '上传文件时发生错误。请确保文件类型被允许，并且文件类型与文件扩展名匹配。',
  },
  ru: {
    title: 'Ошибка',
    body: 'При загрузке файла произошла ошибка. Убедитесь, что тип файла разрешен, и что тип файла соответствует расширению файла.',
  },
  de: {
    title: 'Fehler',
    body: 'Beim Hochladen der Datei ist ein Fehler aufgetreten. Bitte stellen Sie sicher, dass der Dateityp erlaubt ist und dass der Dateityp mit der Dateiendung übereinstimmt.',
  },
  pt: {
    title: 'Erro',
    body: 'Ocorreu um erro ao fazer o upload do arquivo. Por favor, certifique-se de que o tipo de arquivo é permitido e que o tipo de arquivo corresponde à extensão do arquivo.',
  },
  hi: {
    title: 'त्रुटि',
    body: 'आपकी फ़ाइल अपलोड करने में एक त्रुटि हुई. कृपया सुनिश्चित करें कि फ़ाइल प्रकार की अनुमति है और जांचें कि फ़ाइल प्रकार फ़ाइल एक्सटेंशन से मेल खाता है',
  },
  id: {
    title: 'Kesalahan',
    body: 'Terjadi kesalahan saat mengunggah file. Harap pastikan bahwa tipe file diizinkan dan bahwa tipe file sesuai dengan ekstensi file.',
  },
  he: {
    title: 'שגיאה',
    body: 'אירעה שגיאה בעת העלאת הקובץ. וודא שסוג הקובץ והסיומת תואמות ונסה שוב.',
  },
  ko: {
    title: '오류',
    body: '파일을 업로드하는 도중 오류가 발생했습니다. 파일 유형과 확장자가 일치하는지 확인하고 다시 시도하십시오.',
  },
  it: {
    title: 'Errore',
    body: "Si è verificato un errore durante il caricamento del file. Assicurati che il tipo di file sia consentito e verifica che il tipo di file corrisponda all'estensione del file",
  },
  ar: {
    title: 'خطأ',
    body: 'حدث خطأ أثناء تحميل الملف. يرجى التأكد من أن نوع الملف مسموح به وأن نوع الملف يتطابق مع إمتداد الملف.',
  },
}

export const errorUploadingImageMessages: TLocalizedMessages = {
  en: {
    title: 'Error',
    body: 'There was an error uploading your image. Please make sure the file type is allowed and check that the file type matches the file extension.',
  },
  es: {
    title: 'Error',
    body: 'Se produjo un error al subir la imagen. Por favor, asegúrese de que el tipo de archivo está permitido y que el tipo de archivo coincide con la extensión del archivo.',
  },
  fr: {
    title: 'Erreur',
    body: "Une erreur est survenue lors du téléchargement de l'image. Veuillez vérifier que le type de fichier est autorisé et que le type de fichier correspond à l'extension du fichier.",
  },
  ja: {
    title: 'エラー',
    body: '画像のアップロード中にエラーが発生しました。ファイルの種類が許可されていることを確認し、ファイルの種類がファイルの拡張子と一致することを確認してください。',
  },
  zh: {
    title: '错误',
    body: '上传图片时发生错误。请确保文件类型被允许，并且检查文件类型是否与文件扩展名匹配。',
  },
  ru: {
    title: 'Ошибка',
    body: 'Произошла ошибка при загрузке изображения. Убедитесь, что тип файла разрешен, и проверьте, что тип файла соответствует расширению файла.',
  },
  de: {
    title: 'Fehler',
    body: 'Es ist ein Fehler beim Hochladen des Bildes aufgetreten. Bitte stellen Sie sicher, dass der Dateityp erlaubt ist und überprüfen Sie, ob der Dateityp mit der Dateiendung übereinstimmt.',
  },
  pt: {
    title: 'Erro',
    body: 'Ocorreu um erro ao carregar a imagem. Por favor, certifique-se de que o tipo de arquivo é permitido e verifique se o tipo de arquivo corresponde à extensão do arquivo.',
  },
  hi: {
    title: 'त्रुटि',
    body: 'आपके छवि को अपलोड करने में त्रुटि हुई है। कृपया फ़ाइल के प्रकार की अनुमति की है और फ़ाइल के प्रकार के संवर्धित फ़ाइल के अनुसार है।',
  },
  id: {
    title: 'Kesalahan',
    body: 'Terjadi kesalahan saat mengunggah gambar. Pastikan bahwa tipe file diizinkan dan cek apakah tipe file sesuai dengan ekstensi file.',
  },
  he: {
    title: 'שגיאה',
    body: 'אירעה שגיאה בעת העלאת התמונה. אנא וודא שסוג הקובץ מורשה וגם וודא שסוג הקובץ מתאים לסיומת הקובץ.',
  },
  ko: {
    title: '오류',
    body: '이미지를 업로드하는 도중 오류가 발생했습니다. 파일 유형이 허용되어 있는지 확인하고 파일 유형과 파일 확장자가 일치하는지 확인하십시오.',
  },
  it: {
    title: 'Errore',
    body: "Si è verificato un errore durante il caricamento dell'immagine. Assicurarsi che il tipo di file sia consentito e verificare che il tipo di file corrisponda all'estensione del file.",
  },
  ar: {
    title: 'خطأ',
    body: 'حدث خطأ أثناء تحميل الصورة. يرجى التأكد من أن نوع الملف مسموح به وأن نوع الملف يتطابق مع إمتداد الملف.',
  },
}
