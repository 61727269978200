import { IReduxState } from 'interfaces'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { getDeviceLocation } from 'utils/device-location'
import { LocationReducerAction } from 'utils/device-location/types'
import { getUserLanguageCode } from 'utils/user-locale'

export const SET_CURRENT_LOCATION = 'SET_CURRENT_LOCATION'
export const DEVICE_LOCATION_REJECTED = 'SET_DEVICE_LOCATION_ERROR'
export const API_LOCATION_REJECTED = 'SET_API_LOCATION_ERROR'

const INITIAL_STATE = {}

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = INITIAL_STATE, action: LocationReducerAction) => {
  switch (action.type) {
    case SET_CURRENT_LOCATION: {
      const location = action.payload

      return {
        ...state,
        location,
      }
    }

    case DEVICE_LOCATION_REJECTED:
    case API_LOCATION_REJECTED: {
      const error = action.payload

      return {
        ...state,
        code: error.code,
        message: error.message,
      }
    }

    default:
      return state
  }
}

// Actions

export const setCurrentLocation =
  (baseUrl: string, appId: string, useCachedLocation: boolean) =>
  async (
    dispatch: ThunkDispatch<IReduxState, unknown, AnyAction>,
    getState: () => IReduxState
  ): Promise<void> => {
    const state = getState()
    const { location } = getCurrentLocation(state)

    if (location && useCachedLocation) {
      return
    }

    const requestUrl = `${baseUrl}/device-location`
    const language = getUserLanguageCode()

    return getDeviceLocation(dispatch, requestUrl, appId, language)
  }

// Selectors

export const getCurrentLocation = (state: IReduxState) => state.location
