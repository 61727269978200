import { Platform } from 'react-native'

import {
  normalizeFontFamily,
  translateFontWeight,
  handleFontSize,
} from './type'
import { normalizeColor } from './colors'

export const getShadowStyles = shadow => {
  if (!shadow || !shadow.enabled) {
    return null
  }

  const styles = {
    shadowColor: shadow.color,
    shadowOffset: { width: shadow.x || 0, height: shadow.y || 0 },
    shadowOpacity: 1,
    shadowRadius: shadow.size || 0,
  }

  // TODO: Fix this by adding proper android shadows
  // if (Platform.OS === 'android') {
  //   styles.elevation = 1
  // }

  return styles
}

export const normalizeStyles = (prop = {}, attributes = {}, branding = {}) => {
  const { styles: defaultStyles } = prop

  const result = { ...defaultStyles }

  for (const key of Object.keys(defaultStyles)) {
    const target = (attributes.styles && attributes.styles[prop.name]) || {}
    const { fontFamily, fontWeight } = target || defaultStyles

    const currentValue = target[key] || attributes[key] || defaultStyles[key]

    switch (key) {
      case 'fontFamily': {
        result[key] = normalizeFontFamily(currentValue, branding, {
          fontWeight: translateFontWeight(fontWeight, currentValue, branding),
        })
        break
      }
      case 'fontWeight': {
        if (Platform && Platform.OS === 'android') {
          result[key] = null
        } else {
          result[key] = translateFontWeight(currentValue, fontFamily, branding)
        }

        break
      }
      case 'fontSize': {
        result[key] = handleFontSize(currentValue)
        break
      }
      case 'color': {
        result[key] = normalizeColor(currentValue, branding, prop, attributes)
        break
      }
      case 'textAlignment': {
        result.textAlign = currentValue
        delete result.textAlignment
        break
      }
      default: {
        result[key] = currentValue
        break
      }
    }
  }

  return result
}

/**
 * Normalize attribute properties
 * @param {object} attributes
 * @param {object} branding
 * @returns {object} attributes
 */
export const normalizeAttributes = (attributes, branding) => {
  const keys = Object.keys(attributes)

  if (!branding) {
    return attributes
  }

  for (const key of keys) {
    const property = attributes[key]

    if (Array.isArray(property)) continue

    if (property instanceof Object) {
      attributes[key] = normalizeAttributes(property, branding)
    }

    if (typeof property === 'string') {
      if (property.startsWith('@')) {
        if (property.startsWith('@contrast:')) {
          attributes[key] = normalizeColor(property, branding, attributes)
        } else {
          switch (property) {
            case '@primary':
            case '@primaryLight':
            case '@primaryDark':
            case '@secondary':
            case '@secondaryLight':
            case '@secondaryDark':
            case '@text':
            case '@textLight':
            case '@textDark':
            case '@background':
            case '@backgroundLight':
            case '@backgroundDark':
              attributes[key] = normalizeColor(property, branding)
              break
            case '@heading':
            case '@body':
              attributes[key] = normalizeFontFamily(property, branding, {
                fontWeight: attributes.fontWeight,
              })
          }
        }
      }
    }

    switch (key) {
      case 'fontFamily': {
        // handle legacy "magic" fontFamily value "system"
        if (property === 'system') {
          attributes[key] = normalizeFontFamily(property, branding, {
            fontWeight: attributes.fontWeight,
          })
        }
        break
      }
      case 'fontWeight': {
        if (typeof property === 'number') {
          attributes[key] = `${attributes[key]}`
        }

        attributes[key] = translateFontWeight(
          attributes.fontWeight,
          attributes.fontFamily,
          branding
        )
        break
      }
    }
  }

  // we need to remove the fontWeight attribute on Android
  // as it causes issues for Custom Fonts, however, we need
  // the property during normalization to return the correct
  // fontFamily value – e.g. "Roboto-600" – as this value needs
  // to match the Font file name on the device.
  if (Platform && Platform.OS === 'android') {
    attributes = removeFontWeightAttribute(attributes)
  }

  return attributes
}

const removeFontWeightAttribute = attributes => {
  const keys = Object.keys(attributes)

  for (const key of keys) {
    const property = attributes[key]

    if (Array.isArray(property)) continue

    if (property instanceof Object) {
      attributes[key] = removeFontWeightAttribute(property)
    }

    delete attributes.fontWeight
  }

  return attributes
}
