import React from 'react'
import { View, StyleSheet, ActivityIndicator } from 'react-native'

const Loading = () => (
  <View style={styles.wrapper}>
    <ActivityIndicator size="large" color="#999999" />
  </View>
)

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export default Loading
