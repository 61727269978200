/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react'
import { Text } from 'react-native'
import CopyButton from './CopyButton'

export default class LabelSub extends Component {
  state = {
    copyVisible: false,
  }

  handleLongPress = e => {
    const { selectable } = this.props

    if (selectable) {
      this.setState({ copyVisible: true })
    }
  }

  handleHideCopy = () => {
    this.setState({ copyVisible: false })
  }

  // Starts timer for copyable option
  startTimer = () => {
    this._timer = window.setTimeout(this.handleLongPress, 500)
  }

  // Cancels timer for copyable option
  cancelTimer = () => {
    window.clearTimeout(this._timer)
  }

  componentWillUnmount() {
    this.cancelTimer()
  }

  render() {
    const { copyVisible } = this.state
    const { selectable, style, ...props } = this.props

    return (
      <div
        style={styles.wrapper}
        onMouseDown={this.startTimer}
        onMouseLeave={this.cancelTimer}
        onMouseUp={this.cancelTimer}
      >
        <Text {...props} style={[style, styles.text]} />
        {copyVisible ? <CopyButton onHide={this.handleHideCopy} /> : null}
      </div>
    )
  }
}

const styles = {
  wrapper: {
    display: 'flex',
    flex: 1,
    position: 'relative',
  },
  text: {
    flex: 1,
    width: '100%',
  },
}
