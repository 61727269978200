export const dataLimit = 50000000

/**
 * Parse a number to a float.
 * @param {string} value - the localized number
 * @returns {number}
 */
export const parseStringToNumber = value => {
  if (!value || typeof value !== 'string') return value

  // parse string to our standard
  // - dash at start (negative number)
  // - [0-9]
  // - one decimal separator ("." | ",")
  value = [...value]
    // eslint-disable-next-line array-callback-return
    .map((v, index) => {
      if (v.match(new RegExp('[0-9]'))) return v
      if (v.match('-') && index === 0) return v
      if (v.match(new RegExp('[\\,\\.]'))) {
        if (value.indexOf(v) === index) {
          return '.'
        }
      }
    })
    .join('')

  // convert string to number
  value = Number(value)

  if (Number.isNaN(value)) {
    return 0
  } else {
    return value
  }
}
