import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { View, StyleSheet } from 'react-native'
import ObjectRenderer from './ObjectRenderer'

export default class ComponentInstance extends Component {
  static contextTypes = {
    getApp: PropTypes.func,
  }

  getComponent = () => {
    const { object } = this.props
    const { getApp } = this.context
    const { componentId } = object.attributes

    const app = getApp()
    const component = app.components[componentId]

    return component
  }

  render() {
    const { object, active, topScreen, isResponsiveComponent } = this.props
    const { layout } = object

    const component = this.getComponent()

    if (!component) {
      return null
    }

    const children = component.layout.body

    return (
      <View style={[styles.wrapper, layout]}>
        {children.map(obj => (
          <ObjectRenderer
            isReusableComponent
            key={obj.id}
            object={obj}
            component={component}
            active={active}
            topScreen={topScreen}
            isResponsiveComponent={isResponsiveComponent}
          />
        ))}
      </View>
    )
  }
}

const styles = StyleSheet.create({
  wrapper: {},
})
