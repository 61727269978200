export const mapObject = (obj, func) => {
  const newObj = {}

  Object.keys(obj).forEach(key => {
    newObj[key] = func(obj[key])
  })

  return newObj
}

export const filterObject = (obj, keys) => {
  const newObj = {}

  keys.forEach(k => {
    if (obj[k] !== undefined) {
      newObj[k] = obj[k]
    }
  })

  return newObj
}

export const deepGet = (obj, key = []) => {
  if (key.length === 0) {
    return obj
  }

  if (!obj) {
    return undefined
  }

  return deepGet(obj[key[0]], key.slice(1))
}

// eslint-disable-next-line @typescript-eslint/default-param-last
export const deepSet = (obj, key = [], value) => {
  if (key.length === 0) {
    return value
  }

  if (!obj) {
    obj = {}
  }

  return {
    ...obj,
    [key[0]]: deepSet(obj[key[0]], key.slice(1), value),
  }
}

export const buildObject = items => {
  let result = {}

  items.forEach(([key, val]) => {
    result = deepSet(result, key.split('.'), val)
  })

  return result
}

export const toDeep = shallow => {
  let result = {}

  for (const key in shallow) {
    if (key) {
      result = deepSet(result, key.split('.'), shallow[key])
    }
  }

  return result
}

export const toFlat = (deep, parentPath) => {
  let result = {}

  for (const key in deep) {
    const val = deep[key]
    const path = parentPath ? `${parentPath}.${key}` : key

    if (val && typeof val === 'object' && !Array.isArray(val)) {
      result = { ...result, ...toFlat(val, path) }
    } else {
      result[path] = val
    }
  }

  return result
}

export const deepMerge = (obj1, obj2, getKey = null) => {
  if (obj1 !== undefined && obj2 === undefined) {
    return obj1
  }

  if (obj2 !== undefined && !obj1 === undefined) {
    return obj2
  }

  if (getKey && Array.isArray(obj1) && Array.isArray(obj2)) {
    const map = {}
    // eslint-disable-next-line no-return-assign
    obj1.forEach(itm => (map[getKey(itm)] = itm))

    return obj2.map(itm => {
      return deepMerge(map[getKey(itm)], itm, getKey)
    })
  }

  if (
    !obj1 ||
    typeof obj1 !== 'object' ||
    Array.isArray(obj1) ||
    !obj2 ||
    typeof obj2 !== 'object' ||
    Array.isArray(obj2)
  ) {
    return obj2
  }

  const result = {}

  const obj2Keys = new Set(Object.keys(obj2))

  for (const key in { ...obj1, ...obj2 }) {
    if (!obj2[key] && obj2Keys.has(key)) {
      result[key] = obj2[key]

      if (typeof result[key] === 'undefined') {
        delete result[key]
      }

      continue
    }

    result[key] = deepMerge(obj1[key], obj2[key], getKey)
  }

  return result
}

export const isEmpty = obj =>
  [Object, Array].includes((obj || {}).constructor) &&
  !Object.entries(obj || {}).length

export const cloneDeep = obj => JSON.parse(JSON.stringify(obj))
