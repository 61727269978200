export const disableTrialFeature = app => {
  let disableFeature = false

  if (app.Organization) {
    const pastTrialExpiration =
      new Date(app.Organization.trialEndDate) < new Date()
    const paying = app.Organization.active
    disableFeature = !paying && pastTrialExpiration
  }

  return disableFeature
}

export const removeExternalCollections = (getDatasources, dependencies) => {
  const datasources = getDatasources() || {}
  const datasourceIds = Object.keys(datasources)
  let datasource = {}

  if (datasourceIds.length === 1) {
    const datasourceId = datasourceIds[0]
    datasource = datasources[datasourceId] && datasources[datasourceId].tables
  }

  return dependencies.filter(dependency => {
    const { tableId } = dependency
    return datasource[tableId] && datasource[tableId].type !== 'api'
  })
}
