import React, { Component } from 'react'

// eslint-disable-next-line import/no-unresolved
import ReactDOM from 'react-dom'

export default class PortalContainer extends Component {
  render() {
    const { children } = this.props
    const body = document.getElementsByTagName('body')[0]

    return ReactDOM.createPortal(
      <div className="date-picker-portal">{children}</div>,
      body
    )
  }
}
