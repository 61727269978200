import { TLocalizedMessages } from './types'

export const currentLocationMessages: TLocalizedMessages = {
  en: {
    body: 'Current Device Location',
  },
  es: {
    body: 'Tu ubicación',
  },
  fr: {
    body: 'Votre position',
  },
  it: {
    body: 'La tua posizione',
  },
  ja: {
    body: '現在地',
  },
  ko: {
    body: '내 위치',
  },
  pt: {
    body: 'Local onde se encontra',
  },
  ru: {
    body: 'Мое местоположение',
  },
  zh: {
    body: '您的位置',
  },
  de: {
    body: 'Mein Standort',
  },
  hi: {
    body: 'आपकी जगह',
  },
  ar: {
    body: 'موقعك',
  },
  he: {
    body: 'המיקום שלך',
  },
  id: {
    body: 'Lokasi Anda',
  },
}
