const calculateIsMobileBroswer = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera
  const patterns = ['android', 'Android', 'ANDROID', 'iPhone', 'iPad', 'iPod']

  for (let i = 0; i < patterns.length; i += 1) {
    if (userAgent.indexOf(patterns[i]) !== -1) {
      return true
    }
  }

  return false
}

const calculateIsiOSBrowser = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera
  const patterns = ['iPhone', 'iPad', 'iPod']

  for (let i = 0; i < patterns.length; i += 1) {
    if (userAgent.indexOf(patterns[i]) !== -1) {
      return true
    }
  }

  return false
}

const calculateIsAndroidBrowser = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera
  const patterns = ['android', 'Android', 'ANDROID']

  for (let i = 0; i < patterns.length; i += 1) {
    if (userAgent.indexOf(patterns[i]) !== -1) {
      return true
    }
  }

  return false
}

const IS_MOBILE_BROWSER = calculateIsMobileBroswer()
const IS_IOS_BROWSER = calculateIsiOSBrowser()
const IS_ANDROID_BROWSER = calculateIsAndroidBrowser()

export const isMobileBrowser = () => {
  return IS_MOBILE_BROWSER
}

export const isiOSBrowser = () => {
  return IS_IOS_BROWSER
}

export const isAndroidBrowser = () => {
  return IS_ANDROID_BROWSER
}
