import React, { Component } from 'react'
import { View, StyleSheet } from 'react-native'

export default class Row extends Component {
  render() {
    const {
      children,
      object: { layout },
    } = this.props

    return (
      <View style={[styles.wrapper, layout]} pointerEvents="box-none">
        {children}
      </View>
    )
  }
}

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
})
